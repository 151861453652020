import api from '../generalComponents/api';
import { useEffect, useState } from 'react';
import HeaderMain from '../generalComponents/HeaderMain';

// const ganhadoresTeste = [
//   {
//     nome: 'Helder',
//     premio: 'Iphone 12',
//   },
//   {
//     nome: 'Helder',
//     premio: 'Iphone 12',
//   },
// ];

export default function AllWinners() {
  const [ganhadoresTeste, setGanhadoresTeste] = useState([] as any);
  return (
    <>
      <div className="flex flex-col w-full min-h-screen items-center bg-gray-200">
        <HeaderMain />
        <div className="flex flex-col w-full h-full max-w-full max-h-full justify-center p-2">
          <div className="flex flex-col p-2 w-full h-screen max-h-screen overflow-y-auto bg-white rounded-xl items-center">
            <h1 className="text-3xl montserrat-500 p-3">Ganhadores anteriores</h1>
            {ganhadoresTeste?.map((ganhador: any, index: any) => (
              <div
                key={index}
                className="flex flex-row w-full h-24 my-1 rounded-xl items-center"
              >
                {index % 2 === 0 ? (
                  <>
                    <div className="flex flex-row w-full h-24 my-1 border border-green-300 bg-green-100 rounded-xl items-center">
                      <img
                        className="h-20 w-20 rounded-lg ml-2"
                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                        alt=""
                      />
                      <div className="flex flex-col mx-2">
                        <h1 className="text-lg montserrat-500">
                          Ganhador: {ganhador.nome}
                        </h1>
                        <h1 className="text-lg montserrat-500">
                          Prêmio: {ganhador.premio}
                        </h1>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-row w-full h-24 my-1 border border-orange-300 bg-orange-100 rounded-xl items-center">
                      <img
                        className="h-20 w-20 rounded-lg ml-2"
                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                        alt=""
                      />
                      <div className="flex flex-col mx-2">
                        <h1 className="text-lg montserrat-500">
                          Ganhador: {ganhador.nome}
                        </h1>
                        <h1 className="text-lg montserrat-500">
                          Prêmio: {ganhador.premio}
                        </h1>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
