import { useNavigate } from 'react-router-dom';

export default function Tools({ page, nivel }: { page: any; nivel: any }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="p-4 mx-1 mb-1 w-full h-fit bg-white rounded-2xl max-w-full max-h-full flex flex-col">
        <button
          onClick={() => navigate('/dashboard')}
          className="p-1 icon mb-3 w-full flex flex-row items-start hover:text-orange-500 text-start montserrat-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2em"
            height="1.2em"
            viewBox="0 0 24 24"
            className="icon"
          >
            <path
              fill={`${page == 'dashboard' ? '#BD6500' : ''}`}
              d="M20 10a1 1 0 1 0-2 0zM6 10a1 1 0 0 0-2 0zm14.293 2.707a1 1 0 0 0 1.414-1.414zM12 3l.707-.707a1 1 0 0 0-1.414 0zm-9.707 8.293a1 1 0 1 0 1.414 1.414zM7 22h10v-2H7zm13-3v-9h-2v9zM6 19v-9H4v9zm15.707-7.707l-9-9l-1.414 1.414l9 9zm-10.414-9l-9 9l1.414 1.414l9-9zM17 22a3 3 0 0 0 3-3h-2a1 1 0 0 1-1 1zM7 20a1 1 0 0 1-1-1H4a3 3 0 0 0 3 3z"
            />
          </svg>
          <h1
            className={`${page == 'dashboard' ? 'text-orange-500' : ''} ml-2`}
          >
            Início
          </h1>
        </button>
        <button
          onClick={() => navigate('/dashboard/sorteios')}
          className="p-1 mb-3 w-full flex flex-row items-start hover:text-orange-500 text-start montserrat-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2em"
            height="1.2em"
            viewBox="0 0 256 256"
            className="icon"
          >
            <path
              fill={`${page == 'sorteios' ? '#BD6500' : ''}`}
              d="M216 72h-35.08c.39-.33.79-.65 1.17-1A29.53 29.53 0 0 0 192 49.57A32.62 32.62 0 0 0 158.44 16A29.53 29.53 0 0 0 137 25.91a54.94 54.94 0 0 0-9 14.48a54.94 54.94 0 0 0-9-14.48A29.53 29.53 0 0 0 97.56 16A32.62 32.62 0 0 0 64 49.57A29.53 29.53 0 0 0 73.91 71c.38.33.78.65 1.17 1H40a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16v64a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16v-64a16 16 0 0 0 16-16V88a16 16 0 0 0-16-16m-67-35.49a13.69 13.69 0 0 1 10-4.5h.49A16.62 16.62 0 0 1 176 49.08a13.69 13.69 0 0 1-4.5 10c-9.49 8.4-25.24 11.36-35 12.4c1.2-10.59 4.5-25.98 12.5-34.97m-64.09.36A16.63 16.63 0 0 1 96.59 32h.49a13.69 13.69 0 0 1 10 4.5c8.39 9.48 11.35 25.2 12.39 34.92c-9.72-1-25.44-4-34.92-12.39a13.69 13.69 0 0 1-4.5-10a16.6 16.6 0 0 1 4.82-12.16ZM40 88h80v32H40Zm16 48h64v64H56Zm144 64h-64v-64h64Zm16-80h-80V88h80z"
            />
          </svg>
          <h1 className={`${page == 'sorteios' ? 'text-orange-500' : ''} ml-2`}>
            Sorteios
          </h1>
        </button>
        <button
          onClick={() => navigate('/dashboard/usuarios')}
          className="p-1 mb-3 w-full flex flex-row items-start hover:text-orange-500 text-start montserrat-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2em"
            height="1.2em"
            viewBox="0 0 24 24"
            className="icon"
          >
            <g strokeWidth="1.65">
              <circle
                cx="12"
                cy="6"
                r="4"
                fill={`${page == 'usuarios' ? '#BD6500' : ''}`}
              />
              <path
                fill={`${page == 'usuarios' ? '#BD6500' : ''}`}
                d="M20 17.5c0 2.485 0 4.5-8 4.5s-8-2.015-8-4.5S7.582 13 12 13s8 2.015 8 4.5Z"
              />
            </g>
          </svg>
          <h1 className={`${page == 'usuarios' ? 'text-orange-500' : ''} ml-2`}>
            Usuários
          </h1>
        </button>
        <button
          onClick={() => navigate('/dashboard/allorders')}
          className="p-1 mb-3 w-full flex flex-row items-start hover:text-orange-500 text-start montserrat-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2em"
            height="1.2em"
            viewBox="0 0 24 24"
          >
            <path
              fill={`${page == 'pedidos' ? '#BD6500' : ''}`}
              d="m17.275 20.25l3.475-3.45l-1.05-1.05l-2.425 2.375l-.975-.975l-1.05 1.075zM6 9h12V7H6zm12 14q-2.075 0-3.537-1.463T13 18t1.463-3.537T18 13t3.538 1.463T23 18t-1.463 3.538T18 23M3 22V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v6.675q-.7-.35-1.463-.513T18 11H6v2h7.1q-.425.425-.787.925T11.675 15H6v2h5.075q-.05.25-.062.488T11 18q0 1.05.288 2.013t.862 1.837L12 22l-1.5-1.5L9 22l-1.5-1.5L6 22l-1.5-1.5z"
            />
          </svg>
          <h1 className={`${page == 'pedidos' ? 'text-orange-500' : ''} ml-2`}>
            Pedidos
          </h1>
        </button>
        <button
          onClick={() => navigate('/dashboard/financeiro')}
          className="p-1 mb-3 w-full flex flex-row items-start hover:text-orange-500 text-start montserrat-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2em"
            height="1.2em"
            viewBox="0 0 24 24"
            className="icon"
          >
            <path
              fill={`${page == 'financeiro' ? '#BD6500' : ''}`}
              d="M8 13.65V6h3v7.65l-1.5-1.4zm5 1.5V2h3v10.15zM3 18.6V10h3v5.6zm0 2.45l6.45-6.45L13 17.65l5.6-5.6H17v-2h5v5h-2v-1.6l-6.9 6.9l-3.55-3.05l-3.75 3.75z"
            />
          </svg>
          <h1
            className={`${page == 'financeiro' ? 'text-orange-500' : ''} ml-2`}
          >
            Financeiro
          </h1>
        </button>
        <button
          onClick={() => {
            navigate('/dashboard/painelafiliados');
          }}
          className="p-1 mb-3 w-full flex flex-row items-start hover:text-orange-500 text-start montserrat-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2em"
            height="1.2em"
            viewBox="0 0 24 24"
          >
            <path
              fill={`${page == 'afiliados' ? '#BD6500' : ''}`}
              d="M12 14c1.381 0 2.631-.56 3.536-1.465C16.44 11.631 17 10.381 17 9s-.56-2.631-1.464-3.535C14.631 4.56 13.381 4 12 4s-2.631.56-3.536 1.465C7.56 6.369 7 7.619 7 9s.56 2.631 1.464 3.535A4.985 4.985 0 0 0 12 14m8 1a2.495 2.495 0 0 0 2.5-2.5c0-.69-.279-1.315-.732-1.768A2.492 2.492 0 0 0 20 10a2.495 2.495 0 0 0-2.5 2.5A2.496 2.496 0 0 0 20 15m0 .59c-1.331 0-2.332.406-2.917.968C15.968 15.641 14.205 15 12 15c-2.266 0-3.995.648-5.092 1.564C6.312 15.999 5.3 15.59 4 15.59c-2.188 0-3.5 1.09-3.5 2.182c0 .545 1.312 1.092 3.5 1.092c.604 0 1.146-.051 1.623-.133l-.04.27c0 1 2.406 2 6.417 2c3.762 0 6.417-1 6.417-2l-.02-.255c.463.073.995.118 1.603.118c2.051 0 3.5-.547 3.5-1.092c0-1.092-1.373-2.182-3.5-2.182M4 15c.69 0 1.315-.279 1.768-.732A2.492 2.492 0 0 0 6.5 12.5A2.495 2.495 0 0 0 4 10a2.496 2.496 0 0 0-2.5 2.5A2.495 2.495 0 0 0 4 15"
            />
          </svg>
          <h1
            className={`${page == 'afiliados' ? 'text-orange-500' : ''} ml-2`}
          >
            Controle de afiliados
          </h1>
        </button>
        {nivel != '1' && nivel != '0' ? (
          <>
            <button
              onClick={() => {
                navigate('/dashboard/rastreadores');
              }}
              className="p-1 mb-3 w-full flex flex-row items-start hover:text-orange-500 text-start montserrat-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill={`${page == 'rastreadores' ? '#BD6500' : ''}`}
                  d="M6 23H3q-.825 0-1.412-.587T1 21v-3h2v3h3zm12 0v-2h3v-3h2v3q0 .825-.587 1.413T21 23zm-6-4.5q-3 0-5.437-1.775T3 12q1.125-2.95 3.563-4.725T12 5.5t5.438 1.775T21 12q-1.125 2.95-3.562 4.725T12 18.5m0-3q1.45 0 2.475-1.025T15.5 12t-1.025-2.475T12 8.5T9.525 9.525T8.5 12t1.025 2.475T12 15.5m0-2q-.625 0-1.062-.437T10.5 12t.438-1.062T12 10.5t1.063.438T13.5 12t-.437 1.063T12 13.5M1 6V3q0-.825.588-1.412T3 1h3v2H3v3zm20 0V3h-3V1h3q.825 0 1.413.588T23 3v3z"
                />
              </svg>
              <h1
                className={`${page == 'rastreadores' ? 'text-orange-500' : ''} ml-2`}
              >
                Rastreadores
              </h1>
            </button>
          </>
        ) : (
          <></>
        )}
        <button
          onClick={() => navigate('/dashboard/redessociais')}
          className="p-1 mb-3 w-full flex flex-row items-start hover:text-orange-500 text-start montserrat-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2em"
            height="1.2em"
            viewBox="0 0 24 24"
            className="icon"
          >
            <path
              fill={`${page == 'redesSociais' ? '#BD6500' : ''}`}
              d="M18 22q-1.25 0-2.125-.875T15 19q0-.175.025-.363t.075-.337l-7.05-4.1q-.425.375-.95.588T6 15q-1.25 0-2.125-.875T3 12t.875-2.125T6 9q.575 0 1.1.213t.95.587l7.05-4.1q-.05-.15-.075-.337T15 5q0-1.25.875-2.125T18 2t2.125.875T21 5t-.875 2.125T18 8q-.575 0-1.1-.212t-.95-.588L8.9 11.3q.05.15.075.338T9 12t-.025.363t-.075.337l7.05 4.1q.425-.375.95-.587T18 16q1.25 0 2.125.875T21 19t-.875 2.125T18 22"
            />
          </svg>
          <h1
            className={`${page == 'redesSociais' ? 'text-orange-500' : ''} ml-2`}
          >
            Redes sociais
          </h1>
        </button>
        <button
          onClick={() => {
            navigate('/dashboard/faq');
          }}
          className="p-1 mb-3 w-full flex flex-row items-start hover:text-orange-500 text-start montserrat-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2em"
            height="1.2em"
            viewBox="0 0 256 256"
            className="icon"
          >
            <path
              fill={`${page == 'perguntas' ? '#BD6500' : ''}`}
              d="M144 180a16 16 0 1 1-16-16a16 16 0 0 1 16 16m92-52A108 108 0 1 1 128 20a108.12 108.12 0 0 1 108 108m-24 0a84 84 0 1 0-84 84a84.09 84.09 0 0 0 84-84m-84-64c-24.26 0-44 17.94-44 40v4a12 12 0 0 0 24 0v-4c0-8.82 9-16 20-16s20 7.18 20 16s-9 16-20 16a12 12 0 0 0-12 12v8a12 12 0 0 0 23.73 2.56C158.31 137.88 172 122.37 172 104c0-22.06-19.74-40-44-40"
            />
          </svg>
          <h1
            className={`${page == 'perguntas' ? 'text-orange-500' : ''} ml-2`}
          >
            Perguntas frequentes
          </h1>
        </button>
        <button
          onClick={() => navigate('/dashboard/termos')}
          className="p-1 mb-3 w-full flex flex-row items-start hover:text-orange-500 text-start montserrat-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2em"
            height="1.2em"
            viewBox="0 0 24 24"
            className="icon"
          >
            <path
              fill={`${page == 'termos' ? '#BD6500' : ''}`}
              d="M4 4a2 2 0 0 1 2-2h8a1 1 0 0 1 .707.293l5 5A1 1 0 0 1 20 8v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm13.586 4L14 4.414V8zM12 4H6v16h12V10h-5a1 1 0 0 1-1-1zm-4 9a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1m0 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1"
            />
          </svg>
          <h1 className={`${page == 'termos' ? 'text-orange-500' : ''} ml-2`}>
            Termos
          </h1>
        </button>
        <button
          onClick={() => navigate('/dashboard/certificado')}
          className="p-1 mb-3 w-full flex flex-row items-start hover:text-orange-500 text-start montserrat-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2em"
            height="1.2em"
            viewBox="0 0 16 16"
            className="icon"
          >
            <path
              fill={`${page == 'certificado' ? '#BD6500' : ''}`}
              d="M1 4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H7v-.354A4 4 0 0 0 7.465 11H13a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1.535a4 4 0 0 0-1 .82zm5.646 2a4 4 0 0 0-2.275-.983A.5.5 0 0 1 4.5 5h7a.5.5 0 0 1 0 1zM8.5 8a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zM7 9a3 3 0 1 1-6 0a3 3 0 0 1 6 0m-5 3.443V14.5a.5.5 0 0 0 .264.441a.5.5 0 0 0 .513-.026L4 14.1l1.223.816a.5.5 0 0 0 .706-.16a.5.5 0 0 0 .071-.257v-2.056a3.96 3.96 0 0 1-2 .556a3.96 3.96 0 0 1-2-.556"
            />
          </svg>
          <h1
            className={`${page == 'certificado' ? 'text-orange-500' : ''} ml-2`}
          >
            Certificado
          </h1>
        </button>
        <button
          onClick={() => navigate('/dashboard/configuracoes')}
          className="p-1 mb-3 w-full flex flex-row items-start hover:text-orange-500 text-start montserrat-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.2em"
            height="1.2em"
            viewBox="0 0 24 24"
            className="icon"
          >
            <g>
              <path
                fill={`${page == 'configuracoes' ? '#BD6500' : ''}`}
                d="M12 4a1 1 0 0 0-1 1c0 1.692-2.046 2.54-3.243 1.343a1 1 0 1 0-1.414 1.414C7.54 8.954 6.693 11 5 11a1 1 0 1 0 0 2c1.692 0 2.54 2.046 1.343 3.243a1 1 0 0 0 1.414 1.414C8.954 16.46 11 17.307 11 19a1 1 0 1 0 2 0c0-1.692 2.046-2.54 3.243-1.343a1 1 0 1 0 1.414-1.414C16.46 15.046 17.307 13 19 13a1 1 0 1 0 0-2c-1.692 0-2.54-2.046-1.343-3.243a1 1 0 0 0-1.414-1.414C15.046 7.54 13 6.693 13 5a1 1 0 0 0-1-1m-2.992.777a3 3 0 0 1 5.984 0a3 3 0 0 1 4.23 4.231a3 3 0 0 1 .001 5.984a3 3 0 0 1-4.231 4.23a3 3 0 0 1-5.984 0a3 3 0 0 1-4.231-4.23a3 3 0 0 1 0-5.984a3 3 0 0 1 4.231-4.231"
              />
              <path
                fill={`${page == 'configuracoes' ? '#BD6500' : ''}`}
                d="M12 10a2 2 0 1 0 0 4a2 2 0 0 0 0-4m-2.828-.828a4 4 0 1 1 5.656 5.656a4 4 0 0 1-5.656-5.656"
              />
            </g>
          </svg>
          <h1
            className={`${page == 'configuracoes' ? 'text-orange-500' : ''} ml-2`}
          >
            Configurações
          </h1>
        </button>
        <a
          target="_blank"
          href="https://wa.me/+5519993063548"
          className="p-1 w-full flex flex-row items-start hover:text-orange-500 text-start montserrat-800"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" className="icon" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></svg>
          <h1
            className={`ml-2`}
          >
            Fale conosco
          </h1>
        </a>
      </div>
    </>
  );
}
