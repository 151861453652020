import { useEffect, useState } from 'react';
import Order from './Order';
import api from '../../generalComponents/api';
import Swal from 'sweetalert2';

const LastOrders: React.FC = () => {
  const [orders, setOrders] = useState([] as any);
  const [filteredOrders, setFilteredOrders] = useState([] as any);

  useEffect(() => {
    const getOrders = async () => {
      if (typeof document === 'undefined') return;
      if (typeof window === 'undefined') return;

      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/orders/getLastOrders', {
          params: {
            dominio: domain,
            accessToken:
              'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
          },
        })
        .then((response: any) => {
          setOrders(response.data.data);
          setFilteredOrders(response.data.data);
        })
        .catch((error) => {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        });
    };

    getOrders();
  }, []);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setFilteredOrders(filterOrders(searchTerm));
  };

  const filterOrders = (term: string) => {
    return orders.filter((order: any) => {
      return (
        order.comprador.nome.toLowerCase().includes(term.toLowerCase()) ||
        order.comprador.telefone.toLowerCase().includes(term.toLowerCase())
      );
    });
  };

  return (
    <>
      <div className="p-2 mx-1 w-full lg:w-7/12 bg-white rounded-2xl max-w-full h-full max-h-96 flex flex-col">
        <span className='text-center text-orange-500 font-bold'>Últimos pedidos</span>
        <div className="overflow-y-scroll h-auto">
          {filteredOrders.length > 0 ? (
            filteredOrders.map((order: any, index: number) => (
              <Order key={index} index={index} order={order} />
            ))
          ) : (
            <p className="text-center font-bold">Nenhum pedido encontrado</p>
          )}
        </div>
      </div>
    </>
  );
};

export default LastOrders;
