import React, { useRef } from 'react';

const ImageDropzone = ({ images, setImages, oldImages, old, setOldImages }: any) => {
  // Ref para o input de arquivo
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const files = Array.from(event.dataTransfer.files).filter((file) =>
      file.type.startsWith('image/')
    );
    setImages((prevImages: any) => [...prevImages, ...files]);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []).filter((file) =>
      file.type.startsWith('image/')
    );
    setImages((prevImages: any) => [...prevImages, ...files]);
  };

  const handleClick = () => {
    // Utiliza a ref para clicar no input correto
    fileInputRef.current?.click();
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages: any) => prevImages.filter((_: any, i: any) => i !== index));
  };

  const handleRemoveOldImage = (index: number) => {
    setOldImages((prevImages: any) => prevImages.filter((_: any, i: any) => i !== index));
  };

  return (
    <div
      className="flex p-4 flex-col h-56 w-full lg:h-full items-center text-center justify-center bg-emerald-200 rounded-xl cursor-pointer"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onClick={handleClick}
    >
      <input
        ref={fileInputRef} // Ref associada ao input para cada instância
        className="hidden"
        accept="image/*"
        type="file"
        multiple
        tabIndex={-1}
        onChange={handleInputChange}
      />
      <p>Arraste e solte as imagens aqui ou clique para selecionar</p>
      <div className="mt-4 flex flex-row overflow-x-auto">
        {images.map((image: any, index: any) => (
          <div key={index} className="relative w-1/4 p-2">
            <img
              src={URL.createObjectURL(image)}
              alt={`upload-${index}`}
              className="w-full h-auto object-cover rounded-lg shadow-md"
            />
            <button
              className="absolute top-2 right-2 text-white bg-red-600 rounded-full h-6 w-6 flex items-center text-center justify-center"
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveImage(index);
              }}
            >
              &times;
            </button>
          </div>
        ))}
        {old && oldImages.map((image: any, index: any) => (
          <div key={index} className="relative w-1/4 p-2">
            <img
              src={image}
              alt={`old-upload-${index}`}
              className="w-full h-auto object-cover rounded-lg shadow-md"
            />
            <button
              className="absolute top-2 right-2 text-white bg-red-600 rounded-full h-6 w-6 flex items-center text-center justify-center"
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveOldImage(index);
              }}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageDropzone;