/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";

export default function InfoFaturamento({ user }: any) {

  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <div className="mb-1 p-1 max-w-full flex flex-row w-full h-20">
        <div className="p-4 bg-white w-full justify-between items-center text-sm rounded-2xl flex flex-row">
          <div className="flex flex-col">
            {isOpen ?
              <>
                <h1 className="text-orange-700 montserrat-500">
                  Cotas vendidas:{' '}
                  <span className="text-black montserrat-600">{user.cotasVendidas}</span>
                </h1>
                <h1 className="text-orange-700 montserrat-500">
                  Rifas criadas:{' '}
                  <span className="text-black montserrat-600">{user.qtdSorteiosCriados}</span>
                </h1>
                <h1 className="text-orange-700 montserrat-500">
                  Faturamento:{' '}
                  <span className="text-black montserrat-600">R$ {parseFloat(user.faturamento).toFixed(2)}</span>
                </h1>
              </>
              :
              <>
                <h1 className="text-orange-700 montserrat-500">
                  Cotas vendidas:{' '}
                  <span className="text-black montserrat-600">****</span>
                </h1>
                <h1 className="text-orange-700 montserrat-500">
                  Rifas criadas:{' '}
                  <span className="text-black montserrat-600">**</span>
                </h1>
                <h1 className="text-orange-700 montserrat-500">
                  Faturamento:{' '}
                  <span className="text-black montserrat-600">R$ ****.**</span>
                </h1>
              </>}
          </div>
          <button onClick={() => setIsOpen(!isOpen)}>
            <>
              {isOpen ?
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#cc6d00"
                    d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                  />
                </svg>
                :
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="#cc6d00"
                    d="M228 175a8 8 0 0 1-10.92-3l-19-33.2A123.2 123.2 0 0 1 162 155.46l5.87 35.22a8 8 0 0 1-6.58 9.21a8.4 8.4 0 0 1-1.29.11a8 8 0 0 1-7.88-6.69l-5.77-34.58a133 133 0 0 1-36.68 0l-5.77 34.58A8 8 0 0 1 96 200a8.4 8.4 0 0 1-1.32-.11a8 8 0 0 1-6.58-9.21l5.9-35.22a123.2 123.2 0 0 1-36.06-16.69L39 172a8 8 0 1 1-13.94-8l20-35a153.5 153.5 0 0 1-19.3-20a8 8 0 1 1 12.46-10c16.6 20.54 45.64 45 89.78 45s73.18-24.49 89.78-45a8 8 0 1 1 12.44 10a153.5 153.5 0 0 1-19.3 20l20 35a8 8 0 0 1-2.92 11"
                  />
                </svg>
              }
            </>
          </button>
        </div>
      </div>
    </>
  );
}
