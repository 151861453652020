import { Box, LinearProgress, Typography } from "@mui/material";

export default function Profile({ user }: any) {
  const steps = ['Terra', 'Marte', 'Júpiter', 'Saturno', 'Urano'];
  const progress = (user?.nivel - 1) * 25;

  return (
    <>
      <div className="p-1 w-full h-full max-w-full max-h-full flex flex-col">
        <div className="p-4 w-full h-full bg-white rounded-2xl flex flex-col">
          <h1 className="text-orange-700 text-sm">Bem vindo(a)</h1>
          <h1 className="text-green-600 text-xl montserrat-500">
            {user?.nome}
          </h1>
          <hr className="h-px border-0 bg-green-600" />
          <h1 className="text-orange-700 mt-4 mb-2 text-sm">Seu nível</h1>
          <div className="flex items-center justify-center px-6 py-2">
            <Box position="relative" width="100%">
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  height: 8,
                  borderRadius: 5,
                  backgroundColor: '#F5F5F5',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: 'green',
                  },
                }}
              />
              <Box position="absolute" top={-12} left={0} right={0} display="flex" justifyContent="space-between">
                {steps.map((step, index) => (
                  <Box
                    key={index}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    position="relative"
                    sx={{
                      marginLeft: index === 0 ? '-12px' : 0, // Adjust for first circle
                      marginRight: index === steps.length - 1 ? '-12px' : 0, // Adjust for last circle
                    }}
                  >
                    <Box
                      sx={{
                        width: 26,
                        height: 26,
                        borderRadius: '50%',
                        backgroundColor: user?.nivel >= index + 1 ? 'green' : '#F5F5F5',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        top: 2, // Adjust to align with the linear progress bar
                      }}
                    >
                      {user?.nivel == index + 1 && <Box sx={{ width: 16, height: 16, borderRadius: '50%', backgroundColor: 'darkgreen', top: 2 }} />}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}
