import HeaderMain from '../generalComponents/HeaderMain';
import CardMyNumbers from './components/CardMyNumbers';
import { useState, useEffect } from 'react';
import api from '../generalComponents/api';
import { Masks } from '../Masks/masks';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function MyRaffles() {
  const masks = new Masks();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([] as object[]);

  const getOrdersByClient = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;
    const buyer = window.localStorage.getItem('buyer');

    const buyerData = JSON.parse(buyer || '{}');

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const response2 = await api
      .get('/orders/getOrdersByClient', {
        params: {
          dominio: domain,
          nome: buyerData.nome,
          email: buyerData.email,
          telefone: masks.removeNonNumericChars(buyerData.telefone),
        },
      })
      .then((response) => {
        setOrders(response.data.data);
      })
      .catch((error) => {
        if (error.response.data.code != 4000) {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        }
      });
  };

  useEffect(() => {
    getOrdersByClient();
  }, []);

  return (
    <>
      <div className="flex flex-col w-full h-min-screen max-w-full max-h-full">
        <HeaderMain />
        <div className="flex flex-col w-full h-full max-w-full max-h-full justify-center bg-gray-200 p-2">
          <div className="flex flex-col w-full h-screen bg-white rounded-xl shadow-md items-center p-2 overflow-scroll">
            <h1 className="text-3xl">Meus pedidos</h1>
            {orders.length > 0 ? (
              orders.map((order: any) => (
                <CardMyNumbers key={order?.id} order={order} />
              ))
            ) : (
              <div className="flex flex-col justify-center items-center">
                <h1 className="my-3 text-xl montserrat-500">
                  Você ainda não possui pedidos
                </h1>
                <button
                  onClick={() => navigate('/')}
                  className="p-2 bg-green-500 text-white font-medium rounded-xl hover:bg-green-600 transition ease-in-out"
                >
                  Clique aqui para começar a participar!
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
