"use client"
import api from "../generalComponents/api"
import { useEffect, useState } from "react"

export default function MercadoPago() {
    const [code, setCode] = useState('')
    const [link, setLink] = useState('')

    useEffect(() => {
        if (typeof document === 'undefined' || typeof window === 'undefined') {
            return;
        }

        const dominio = localStorage.getItem('link') ? localStorage.getItem('link') : ''

        if (dominio) {
            setLink(dominio)
        }
        setCode(window.location.search.slice(1).split('=')[1])
        postToken(window.location.search.slice(1).split('=')[1], location.hostname)
    }, [])
    
    
    const postToken = async (token: string, link: string) => {
        if (typeof document === 'undefined' || typeof window === 'undefined') {
            return;
        }
        if (token == '' || link == '') return

        const obj = {
            link: link,
            code: token
        }
        const response = await api
            .get("/cookie")
            .catch((error) => console.log(error));
        const _csrf = response?.data?.csrfToken;
        api.defaults.withCredentials = true;
        api.defaults.headers.common["X-CSRF-Token"] = _csrf;

        await api
            .post('/pay/token', obj)
            .then((response) => {
                location.href = `/dashboard`
            })
            .catch((error) => {
                console.log(error)
            })

    }

    return (
        <></>
    )
}