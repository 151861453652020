/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import api from '../generalComponents/api';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Autorizacao from '../generalComponents/Autorizacao';
import Swal from 'sweetalert2';
import DashboardDrawer from '../generalComponents/DashboardDrawer';

export default function AfiliadosControl() {
  const navigate = useNavigate();
  const [termoPesquisa, setTermoPesquisa] = useState('');
  const [users, setUsers] = useState([] as object[]);
  const [autorizacao, setAutorizacao] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([] as object[]);
  //controle do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  useEffect(() => {
    const getBuyers = async () => {
      if (typeof document == 'undefined') return;
      if (typeof window == 'undefined') return;

      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/buyers/getAllAccounts', {
          params: {
            dominio: domain,
            accessToken:
              'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
          },
        })
        .then((response) => {
          setUsers(response.data);
          setFilteredUsers(response.data);
        })
        .catch((error) => {
          if (error.response.data.code) {
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          }
        });
    };

    getBuyers();
  }, []);

  const handleLimparFaturamento = async (afiliadoCode: string) => {
    Swal.fire({
      title: 'Deseja realmente limpar o faturamento?',
      text: 'Essa ação não pode ser desfeita',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, limpar',
      cancelButtonText: 'Cancelar',
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (afiliadoCode == '') {
          console.log('Campo afiliado vazio');
          return;
        }
        if (typeof document == 'undefined') return;
        if (typeof window == 'undefined') return;

        const domain = window.location.hostname;

        const response = await api
          .get('/cookie')
          .catch((error) => console.log(error));
        const _csrf = response?.data?.csrfToken;
        api.defaults.headers.common['X-CSRF-Token'] = _csrf;

        await api
          .put('/buyers/clearAfiliadoFaturamento', {
            dominio: domain,
            accessToken:
              'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
            afiliado: afiliadoCode,
          })
          .then((response) => {
            console.log(response.data);
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'Faturamento limpo com sucesso',
              showConfirmButton: true,
              timer: 1500,
              confirmButtonColor: '#00662c',
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          });
      } else {
        return;
      }
    });
  };

  const handleChangeAfiliadoRestricao = async (afiliadoCode: string) => {
    Swal.fire({
      title: 'Deseja realmente alterar a restrição do afiliado?',
      text: 'Essa ação pode ser desfeita a qualquer momento',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, alterar',
      cancelButtonText: 'Cancelar',
    }).then(async (response) => {
      if (response.isConfirmed) {
        if (afiliadoCode == '') {
          console.log('Campo afiliado vazio');
          return;
        }
        if (typeof document == 'undefined') return;
        if (typeof window == 'undefined') return;

        const domain = window.location.hostname;

        const response = await api
          .get('/cookie')
          .catch((error) => console.log(error));
        const _csrf = response?.data?.csrfToken;
        api.defaults.headers.common['X-CSRF-Token'] = _csrf;

        await api
          .put('/buyers/changeAfiliadoRestricao', {
            dominio: domain,
            accessToken:
              'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
            afiliado: afiliadoCode,
          })
          .then((response) => {
            console.log(response.data);
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'Restrição alterada com sucesso',
              showConfirmButton: true,
              timer: 1500,
              confirmButtonColor: '#00662c',
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          });
      } else {
        return;
      }
    });
  };

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '' };
      decodeJwt = jwtDecode(token);

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
  }, []);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermoPesquisa(event.target.value);
    setFilteredUsers(filterUsers(event.target.value));
  };

  const filterUsers = (term: string) => {
    return users.filter((user: any) => {
      return (
        user.nome.toLowerCase().includes(term.toLowerCase()) ||
        user.telefone.includes(term)
      );
    });
  };

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="flex flex-col w-full h-full bg-orange-50">
            <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
              <button onClick={() => navigate('/')} className="flex flex-row">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
              <button
                className="block md:hidden lg:hidden"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.9em"
                  height="1.9em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="black"
                    d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                  />
                </svg>
              </button>
              <DashboardDrawer
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                page="afiliados"
                nivel={'1'}
              />
            </div>
            <div className="flex flex-col lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="afiliados" />
              </div>
              <div className="flex flex-col w-full h-screen max-h-screen bg-white rounded-lg shadow-lg">
                <div className="flex flex-row w-full max-w-full">
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                    className="mr-2"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Pesquisar por nome ou telefone"
                      variant="outlined"
                      color="success"
                      value={termoPesquisa}
                      onChange={handleFilter}
                    />
                  </Box>
                </div>
                <div className="flex flex-col w-full h-full p-2 overflow-y-scroll">
                  {filteredUsers.map((user: any) =>
                    user?.afiliado != '' &&
                    user?.afiliado != null &&
                    user?.afiliado != undefined ? (
                      <>
                        <div
                          className="text-xs lg:text-md md:text-md flex flex-row bg-gray-100 justify-between w-full p-3 my-1 rounded-xl"
                          key={user.id}
                        >
                          <div className="flex flex-col">
                            <span className="text-xs">{user.afiliado}</span>
                            <span>{user.nome}</span>
                            <span>{user.telefone}</span>
                            <span>{user.email}</span>
                          </div>
                          <div className="flex flex-col">
                            <span>
                              Faturamento: R${' '}
                              {parseFloat(user.faturamento).toFixed(2)}
                            </span>
                            <span>Vendas: {user.totalVendas}</span>
                            <div className="flex flex-row w-full">
                              <button
                                onClick={() =>
                                  handleChangeAfiliadoRestricao(user.afiliado)
                                }
                                className="bg-blue-600 mx-1 w-fit h-fit text-white font-medium p-2 rounded-lg hover:bg-blue-700 transition ease-in-out"
                              >
                                {user.restrito ? 'liberar' : 'restringir'}
                              </button>
                              <button
                                onClick={() =>
                                  handleLimparFaturamento(user.afiliado)
                                }
                                className="bg-red-600 mx-1 w-fit h-fit text-white font-medium p-2 rounded-lg hover:bg-red-700 transition ease-in-out"
                              >
                                Limpar faturamento
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null,
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
