import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './api';
import { jwtDecode } from 'jwt-decode';
import DOMPurify from 'dompurify';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';

export default function HeaderMain() {
  const [isOpen, setIsOpen] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState({ telefone: '', nome: '', email: '' });
  const [client, setClient] = useState({} as any);
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAdmin(false);
        return;
      }
      let decodeJwt = { email: '' };
      decodeJwt = jwtDecode(token);

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAdmin(true);
        })
        .catch((error) => {
          setAdmin(false);
        });
    };
    authorization();
    getClientInfo();

    if (localStorage.getItem('buyer') == null) {
      setLogin(false);
    } else {
      setLogin(true);
      user.nome = JSON.parse(
        DOMPurify.sanitize(localStorage.getItem('buyer') as string),
      ).nome;
      user.email = JSON.parse(
        DOMPurify.sanitize(localStorage.getItem('buyer') as string),
      ).email;
      user.telefone = JSON.parse(
        DOMPurify.sanitize(localStorage.getItem('buyer') as string),
      ).telefone;
    }
  }, []);

  const getClientInfo = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/client/getAccountConfig', { params: { dominio: domain } })
      .then((response) => {
        setClient(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logoutHandler = () => {
    if (typeof document === 'undefined') {
      return;
    }
    localStorage.removeItem('buyer');
    setLogin(false);
    location.reload();
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const drawerContent = (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        backgroundColor: 'black',
        position: 'relative',
        paddingTop: '50px',
      }}
      role="presentation"
    >
      <IconButton
        onClick={toggleNavbar}
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          color: 'white',
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.8em"
          height="1.8em"
          viewBox="0 0 24 24"
        >
          <g
            fill="none"
            stroke="white"
            stroke-dasharray="16"
            stroke-dashoffset="16"
            stroke-linecap="round"
            stroke-width="1.95"
          >
            <path d="M7 7L17 17">
              <animate
                fill="freeze"
                attributeName="stroke-dashoffset"
                dur="0.3s"
                values="16;0"
              />
            </path>
            <path d="M17 7L7 17">
              <animate
                fill="freeze"
                attributeName="stroke-dashoffset"
                begin="0.3s"
                dur="0.3s"
                values="16;0"
              />
            </path>
          </g>
        </svg>
      </IconButton>
      <Box
        sx={{
          width: '80%',
          maxWidth: '500px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <List sx={{ width: '100%' }}>
          {login && (
            <ListItem
              disablePadding
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <ListItemText
                primary={`Olá, ${user.nome}!`}
                sx={{ color: 'white', textAlign: 'center', mb: 2 }}
              />
            </ListItem>
          )}
          {admin && (
            <ListItem disablePadding sx={{ borderBottom: '1px solid gray' }}>
              <ListItemButton onClick={() => navigate('/dashboard')}>
                <ListItemText
                  primary="Dashboard"
                  sx={{
                    color: 'white',
                  }}
                />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding sx={{ borderBottom: '1px solid gray' }}>
            <ListItemButton onClick={() => navigate('/')}>
              <ListItemText
                primary="Início"
                sx={{
                  color: 'white',
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ borderBottom: '1px solid gray' }}>
            <ListItemButton onClick={() => navigate('/meusnumeros')}>
              <ListItemText primary="Meus pedidos" sx={{ color: 'white' }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ borderBottom: '1px solid gray' }}>
            <ListItemButton onClick={() => navigate('/')}>
              <ListItemText primary="Sorteios" sx={{ color: 'white' }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ borderBottom: '1px solid gray' }}>
            <ListItemButton onClick={() => navigate('/allwinners')}>
              <ListItemText primary="Ganhadores" sx={{ color: 'white' }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ borderBottom: '1px solid gray' }}>
            <ListItemButton onClick={() => navigate('/afiliados')}>
              <ListItemText primary="Afiliados" sx={{ color: 'white' }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ borderBottom: '1px solid gray' }}>
            <ListItemButton onClick={() => navigate('/faq')}>
              <ListItemText
                primary="Perguntas frequentes"
                sx={{ color: 'white' }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ borderBottom: '1px solid gray' }}>
            <ListItemButton onClick={() => navigate('/termosdeuso')}>
              <ListItemText primary="Termos de uso" sx={{ color: 'white' }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={login ? logoutHandler : () => navigate('/login')}
            >
              <ListItemText
                primary={login ? 'Sair da conta' : 'Fazer login'}
                sx={{
                  textAlign: 'center',
                  mt: 1,
                  backgroundColor: 'green',
                  color: 'white',
                  fontWeight: 'bold',
                  fontFamily: 'Montserrat',
                  padding: '10px',
                  borderRadius: '5px',
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <div className="py-3 px-5 flex flex-row justify-between items-center h-18 w-full bg-black z-10">
        <button
          onClick={() => {
            navigate('/');
          }}
        >
          <img
            className="w-fit h-fit max-h-14 max-w-20"
            src={client?.logo}
            alt="logo.png"
          />
        </button>
        <div>
          <button onClick={() => toggleNavbar()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2em"
              height="2em"
              viewBox="0 0 16 16"
            >
              <path
                fill="white"
                d="M2 3.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0 4a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m0 4a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"
              />
            </svg>
          </button>
        </div>
      </div>
      <Drawer anchor="top" open={isOpen} onClose={toggleNavbar}>
        {drawerContent}
      </Drawer>
    </>
  );
}
