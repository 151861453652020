import { useNavigate } from 'react-router-dom';
import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import api from '../generalComponents/api';
import { jwtDecode } from 'jwt-decode';
import Autorizacao from '../generalComponents/Autorizacao';
import DashboardDrawer from '../generalComponents/DashboardDrawer';

export default function Certificado() {
  const navigate = useNavigate();

  const [certificado, setCertificado] = useState('');
  const [autorizacao, setAutorizacao] = useState(false);
  //controle do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '' };
      decodeJwt = jwtDecode(token);

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
  }, []);

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="flex flex-col w-full h-full bg-orange-50">
            <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
              <button onClick={() => navigate('/')} className="flex flex-row">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
              <button
                className="block md:hidden lg:hidden"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.9em"
                  height="1.9em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="black"
                    d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                  />
                </svg>
              </button>
              <DashboardDrawer
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                page="certificado"
                nivel={'1'}
              />
            </div>
            <div className="flex flex-col lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="certificado" />
              </div>
              <div className="flex flex-col w-full h-screen max-h-screen overflow-y-scroll p-2 bg-white rounded-lg shadow-lg">
                <div className="flex flex-row w-full my-4 p-2 bg-sky-200 rounded-xl lg:items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 24 24"
                    className="mr-2 min-w-8 min-h-8"
                  >
                    <path
                      fill="#0aa1ff"
                      d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"
                    />
                  </svg>
                  <h1 className="text-sky-800 montserrat-500">
                    Caso seja certificado, adicione aqui o texto fornecido pela
                    certificadora.
                  </h1>
                </div>
                <div>
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { mt: 2, width: '100%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      <TextField
                        color="success"
                        id="outlined-multiline-static"
                        label="Texto do certificado"
                        multiline
                        rows={10}
                        placeholder="Escreva o texto do certificado aqui..."
                        value={certificado}
                        onChange={(e: any) => setCertificado(e.target.value)}
                      />
                    </div>
                  </Box>
                </div>
                <button className="my-4 p-2 w-full bg-green-500 rounded-xl text-white montserrat-500 hover:bg-green-600 transition ease-in-out">
                  Salvar certificado
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
