/* eslint-disable @typescript-eslint/no-explicit-any */
export default function MainRaffle({ raffle }: any) {
  return (
    <>
      <div className="flex flex-col w-full pb-2 bg-white rounded-lg shadow-lg mb-1">
        <img
          className="max-w-full w-screen rounded-t-lg max-h-64 md:max-h-80 lg:max-h-96 object-cover"
          src={raffle.imagens[0]}
          alt=""
        />
        <div className="flex flex-col p-2">
          <h1 className="text-xl montserrat-600">{raffle.nome}</h1>
          <h1 className="text-gray-700 montserrat-500">{raffle.subtitulo}</h1>
          {raffle.status == 'ativo' ? (
            <>
              <h1 className="text-white px-1 text-sm rounded-lg bg-green-600 w-fit animate-pulse">
                Adquira agora!
              </h1>
            </>
          ) : raffle.status == 'concluido' ? (
            <>
              <h1 className="text-white px-1 text-sm rounded-lg bg-gray-600 w-fit">
                Concluído!
              </h1>
            </>
          ) : (
            <>
              <h1 className="text-white px-1 text-sm rounded-lg bg-gray-600 w-fit">
                Aguardando liberação!
              </h1>
            </>
          )}
        </div>
      </div>
    </>
  );
}
