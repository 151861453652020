import { useEffect, useState } from 'react';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import SorteioCard from './components/SorteioCard';
import { useNavigate } from 'react-router-dom';
import api from '../generalComponents/api';
import Autorizacao from '../generalComponents/Autorizacao';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';
import DashboardDrawer from '../generalComponents/DashboardDrawer';

export default function Sorteios() {
  const navigate = useNavigate();
  const [raffles, setRaffles] = useState([] as any);
  const [autorizacao, setAutorizacao] = useState(false);
  const [existSorteio, setExistSorteio] = useState(false);
  const [client, setClient] = useState({} as any);
  const [nivel, setNivel] = useState('1');
  // controle do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const handleGetClient = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/client/getAccountData', {
        params: {
          dominio: domain,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
        },
      })
      .then((response) => {
        setClient(response.data);
        setNivel(response.data.nivel);
      })
      .catch((error) => {
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  useEffect(() => {
    const getRaffles = async () => {
      if (typeof document == 'undefined') return;
      if (typeof window == 'undefined') return;

      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/raffles/getAllRaffles', { params: { dominio: domain } })
        .then((response): any => {
          setExistSorteio(true);
          setRaffles(response.data);
        })
        .catch((error) => {
          if (error.response.data.code) {
            if (error.response.data.code != 7001) {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: `ERRO - ${error.response.data.code}`,
                text: `${error.response.data.error}`,
              });
            }
          }
        });
    };

    getRaffles();
  }, []);

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '' };
      decodeJwt = jwtDecode(token);

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
    handleGetClient();
  }, []);

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="flex flex-col w-full h-full bg-orange-50">
            <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
              <button onClick={() => navigate('/')} className="flex flex-row">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
              <button
                className="block md:hidden lg:hidden"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.9em"
                  height="1.9em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="black"
                    d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                  />
                </svg>
              </button>
              <DashboardDrawer
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                page="sorteios"
                nivel={'1'}
              />
            </div>
            <div className="flex flex-col lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="sorteios" />
              </div>
              <div className="flex flex-col w-full h-screen max-h-screen overflow-y-scroll p-2 bg-white rounded-lg shadow-lg">
                <div className="flex p-1 w-full justify-center">
                  <button
                    onClick={() => navigate('/dashboard/newraffle')}
                    className="p-2 bg-green-500 rounded-xl w-full text-white hover:bg-green-600"
                  >
                    Novo Sorteio
                  </button>
                </div>
                {existSorteio ? (
                  <>
                    {raffles.map((raffle: any, index: any) => (
                      <SorteioCard key={index} raffle={raffle} nivel={nivel} />
                    ))}
                  </>
                ) : (
                  <>
                    <div className="flex flex-col w-full h-full items-center justify-center">
                      <h1 className="text-lg montserrat-600">
                        Não há sorteios cadastrados
                      </h1>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
