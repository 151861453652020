import Profile from './Profile';

export default function Welcome({user} : any) {
  return (
    <>
      <div className="flex flex-col lg:flex-row w-full h-full">
        <div className="w-full lg:w-1/3">
          <Profile user={user} />
        </div>
        <div className="p-1 w-full lg:w-3/12 h-full max-w-full max-h-full flex flex-col">
          <div className="p-2 w-full h-full items-center justify-center bg-white rounded-2xl flex flex-col">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5em"
              height="5em"
              viewBox="0 0 24 24"
            >
              <g fill="none" stroke="#cc6d00" strokeWidth="0.5">
                <circle cx="12" cy="6" r="4" />
                <path d="M20 17.5c0 2.485 0 4.5-8 4.5s-8-2.015-8-4.5S7.582 13 12 13s8 2.015 8 4.5Z" />
              </g>
            </svg>
            <h1 className="text-orange-700 text-xl m-1">Usuários</h1>
            <h1 className="m-1 text-2xl">{user.usuarios}</h1>
          </div>
        </div>
        <div className="p-1 w-full lg:w-3/12 h-full max-w-full max-h-full flex flex-col">
          <div className="p-2 w-full h-full items-center justify-center bg-white rounded-2xl flex flex-col">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5em"
              height="5em"
              viewBox="0 0 512 512"
            >
              <path
                fill="none"
                stroke="#cc6d00"
                strokeMiterlimit="10"
                strokeWidth="10"
                d="M366.05 146a46.7 46.7 0 0 1-2.42-63.42a3.87 3.87 0 0 0-.22-5.26l-44.13-44.18a3.89 3.89 0 0 0-5.5 0l-70.34 70.34a23.62 23.62 0 0 0-5.71 9.24a23.66 23.66 0 0 1-14.95 15a23.7 23.7 0 0 0-9.25 5.71L33.14 313.78a3.89 3.89 0 0 0 0 5.5l44.13 44.13a3.87 3.87 0 0 0 5.26.22a46.69 46.69 0 0 1 65.84 65.84a3.87 3.87 0 0 0 .22 5.26l44.13 44.13a3.89 3.89 0 0 0 5.5 0l180.4-180.39a23.7 23.7 0 0 0 5.71-9.25a23.66 23.66 0 0 1 14.95-15a23.62 23.62 0 0 0 9.24-5.71l70.34-70.34a3.89 3.89 0 0 0 0-5.5l-44.13-44.13a3.87 3.87 0 0 0-5.26-.22a46.7 46.7 0 0 1-63.42-2.32Z"
              />
              <path
                fill="none"
                stroke="#cc6d00"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="10"
                d="m250.5 140.44l-16.51-16.51m60.53 60.53l-11.01-11m55.03 55.03l-11-11.01m60.53 60.53l-16.51-16.51"
              />
            </svg>
            <h1 className="text-orange-700 text-xl m-1">Ticket médio</h1>
            <h1 className="m-1 text-2xl">R$ {(user.faturamento/user.qtdPedidos).toFixed(2) != "NaN" ? (user.faturamento/user.qtdPedidos).toFixed(2) : "0.0"}</h1>
          </div>
        </div>
        <div className="p-1 w-3/12 h-full max-w-full max-h-full hidden lg:flex flex-col">
          <div className="p-2 w-full h-full items-center justify-center bg-green-200 rounded-2xl flex flex-col">
            <img
              className="max-w-full max-h-full"
              src="https://digitallucky-bucket.s3.amazonaws.com/logo_digital_lucky.png"
              alt="logo.png"
            />
          </div>
        </div>
      </div>
    </>
  );
}
