/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import HeaderMain from '../generalComponents/HeaderMain';
import MainRaffle from './components/MainRaffle';
import Raffle from './components/Raffle';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../generalComponents/api';
import Swal from 'sweetalert2';

export default function Home() {
  const [raffles, setRaffles] = useState([] as any);
  const [mainRaffle, setMainRaffle] = useState({} as any);
  const [existSorteio, setExistSorteio] = useState(false);
  const navigate = useNavigate();

  const redirectRaffleClick = (raffle: any) => {
    navigate(`/singleraffle/${raffle?.link}`);
  };

  useEffect(() => {
    const getRaffles = async () => {
      if (typeof document == 'undefined') return;
      if (typeof window == 'undefined') return;

      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      const response2 = await api
        .get('/raffles/getAllRaffles', { params: { dominio: domain } })
        .then((response) => {
          setExistSorteio(true);
          filterRaffles(response.data);
        })
        .catch((error) => {
          if (error.response.data.code) {
            if (error.response.data.code != 7001) {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: `ERRO - ${error.response.data.code}`,
                text: `${error.response.data.error}`,
              });
            }
          }
        });
    };

    getRaffles();
  }, []);

  const filterRaffles = (raffles: any) => {
    if (raffles.length == 0) return;

    let currentMainRaffle = {prioridade: 0}
    for (const raffle of raffles) {
      if (raffle.mostrarSite) {
        currentMainRaffle = raffle
        break
      }
    }

    for (const raffle of raffles) {
      if (raffle.mostrarSite) {
        if (raffle.prioridade > currentMainRaffle.prioridade) {
          currentMainRaffle = raffle;
        }
      }
    }
    setMainRaffle(currentMainRaffle);

    const filteredRaffles = raffles
      .filter((raffle: any) => raffle !== currentMainRaffle)
      .filter((raffle: any) => raffle.mostrarSite);

    if (filterRaffles.length == 0) return;

    const sortedRaffles = filteredRaffles.sort(
      (a: any, b: any) => b.prioridade - a.prioridade,
    );

    setRaffles(sortedRaffles);
  };

  return (
    <>
      <div className="flex flex-col w-full h-full bg-white">
        <HeaderMain />
        <div className="h-full w-full bg-white justify-center px-2 my-1">
          <div className="items-center justify-center flex py-3">
            <div className="max-w-full mx-4 md:max-w-3xl lg:max-w-5xl bg-zinc-200 rounded-xl p-3 mt-[-1rem]">
              <div className="flex flex-rol items-end gap-2 my-3">
                <div className="flex h-full items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 128 128"
                  >
                    <radialGradient
                      id="notoFire0"
                      cx="68.884"
                      cy="124.296"
                      r="70.587"
                      gradientTransform="matrix(-1 -.00434 -.00713 1.6408 131.986 -79.345)"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset=".314" stopColor="#ff9800" />
                      <stop offset=".662" stopColor="#ff6d00" />
                      <stop offset=".972" stopColor="#f44336" />
                    </radialGradient>
                    <path
                      fill="url(#notoFire0)"
                      d="M35.56 40.73c-.57 6.08-.97 16.84 2.62 21.42c0 0-1.69-11.82 13.46-26.65c6.1-5.97 7.51-14.09 5.38-20.18c-1.21-3.45-3.42-6.3-5.34-8.29c-1.12-1.17-.26-3.1 1.37-3.03c9.86.44 25.84 3.18 32.63 20.22c2.98 7.48 3.2 15.21 1.78 23.07c-.9 5.02-4.1 16.18 3.2 17.55c5.21.98 7.73-3.16 8.86-6.14c.47-1.24 2.1-1.55 2.98-.56c8.8 10.01 9.55 21.8 7.73 31.95c-3.52 19.62-23.39 33.9-43.13 33.9c-24.66 0-44.29-14.11-49.38-39.65c-2.05-10.31-1.01-30.71 14.89-45.11c1.18-1.08 3.11-.12 2.95 1.5"
                    />
                    <radialGradient
                      id="notoFire1"
                      cx="64.921"
                      cy="54.062"
                      r="73.86"
                      gradientTransform="matrix(-.0101 .9999 .7525 .0076 26.154 -11.267)"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset=".214" stopColor="#fff176" />
                      <stop offset=".328" stopColor="#fff27d" />
                      <stop offset=".487" stopColor="#fff48f" />
                      <stop offset=".672" stopColor="#fff7ad" />
                      <stop offset=".793" stopColor="#fff9c4" />
                      <stop
                        offset=".822"
                        stopColor="#fff8bd"
                        stopOpacity="0.804"
                      />
                      <stop
                        offset=".863"
                        stopColor="#fff6ab"
                        stopOpacity="0.529"
                      />
                      <stop
                        offset=".91"
                        stopColor="#fff38d"
                        stopOpacity="0.209"
                      />
                      <stop offset=".941" stopColor="#fff176" stopOpacity="0" />
                    </radialGradient>
                    <path
                      fill="url(#notoFire1)"
                      d="M76.11 77.42c-9.09-11.7-5.02-25.05-2.79-30.37c.3-.7-.5-1.36-1.13-.93c-3.91 2.66-11.92 8.92-15.65 17.73c-5.05 11.91-4.69 17.74-1.7 24.86c1.8 4.29-.29 5.2-1.34 5.36c-1.02.16-1.96-.52-2.71-1.23a16.09 16.09 0 0 1-4.44-7.6c-.16-.62-.97-.79-1.34-.28c-2.8 3.87-4.25 10.08-4.32 14.47C40.47 113 51.68 124 65.24 124c17.09 0 29.54-18.9 19.72-34.7c-2.85-4.6-5.53-7.61-8.85-11.88"
                    />
                  </svg>
                </div>
                <h1 className="montserrat-700 text-2xl font-bold">Campanhas</h1>
                <span className="montserrat-500 text-zinc-500">
                  Escolha sua sorte
                </span>
              </div>
              <div className="flex flex-col">
                {existSorteio ? (
                  <>
                    <button
                      className="items-start justify-start text-start"
                      onClick={() => redirectRaffleClick(mainRaffle)}
                    >
                      {mainRaffle.nome ? (
                        <>
                          <MainRaffle raffle={mainRaffle} />
                        </>
                      ) : (
                        <></>
                      )}
                    </button>
                    {raffles.map((raffle: any, index: any) => (
                      <button
                        className="items-start justify-start text-start"
                        key={index}
                        onClick={() => redirectRaffleClick(raffle)}
                      >
                        <Raffle key={raffle._id} raffle={raffle} />
                      </button>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="w-full flex flex-col items-center justify-center">
                      <h1 className="montserrat-700 text-2xl font-bold">
                        Não há sorteios disponíveis
                      </h1>
                      <h1 className="montserrat-500 text-zinc-500">
                        Volte mais tarde
                      </h1>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
