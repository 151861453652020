interface RaffleCardAfiliadosProps {
  raffle: any;
  dominio: string;
  afiliadoCode: string;
}

const RaffleCardAfiliados: React.FC<RaffleCardAfiliadosProps> = ({
  raffle,
  dominio,
  afiliadoCode,
}) => {
  const handleCopy = (dominio: string, link: string) => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const copy = dominio + '/singleraffle/' + link + '?afiliado=' + afiliadoCode;
    navigator.clipboard.writeText(copy);
  };

  return (
    <>
      <div className="p-2 flex flex-row w-full h-40 bg-white rounded-xl items-center mb-2">
        <img
          src={raffle?.imagens[0]}
          alt=""
          className="w-36 h-36 rounded-xl object-cover"
        />
        <div className="flex flex-col w-full text-start ml-2">
          <h1>{raffle?.nome}</h1>
          <h1 className="text-xs">Copie o link abaixo e compartilhe</h1>
          <h1 className="text-xs">Contate o promotor da ação para se tornar um afiliado</h1>
          <span
            onClick={(e) => handleCopy(dominio, raffle?.link)}
            className="flex flex-col md:flex-row lg:flex-row w-fit md:w-full lg:w-full items-center justify-between font-medium cursor-pointer"
          >
            <h1 className="hidden lg:block md:block">
              https://{dominio}/singleraffle/{raffle?.link}?afiliado=
              {afiliadoCode}
            </h1>
            <button
              onClick={(e) => handleCopy(dominio, raffle?.link)}
              className="bg-green-500 rounded-lg p-2 hover:bg-green-600 transition ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 16 16"
              >
                <path
                  fill="white"
                  fill-rule="evenodd"
                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                />
              </svg>
            </button>
          </span>
        </div>
      </div>
    </>
  );
};
export default RaffleCardAfiliados;
