import ReactEcharts from 'echarts-for-react';
import { useNavigate } from 'react-router-dom';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import { useEffect, useState } from 'react';
import api from '../generalComponents/api';
import { jwtDecode } from 'jwt-decode';
import Autorizacao from '../generalComponents/Autorizacao';
import Swal from 'sweetalert2';
import DashboardDrawer from '../generalComponents/DashboardDrawer';
import { Masks } from '../Masks/masks';

export default function Financeiro() {
  const masks = new Masks();
  //controle do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [dailyData, setDailyData] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [weekData, setWeekData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [monthData, setMonthData] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0,
  ]);
  const [monthTicketData, setMonthTicketData] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [faturamentoData, setFaturamentoData] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [loading, setLoading] = useState(true);
  const [autorizacao, setAutorizacao] = useState(false);
  const [orders, setOrders] = useState([] as any);
  const [enableDaysButton, setEnableDaysButton] = useState(true);
  const [dateLocal, setDateLocal] = useState(
    new Date(
      new Date().toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' }),
    ),
  );
  const navigate = useNavigate();

  const handleChangeDateLocal = (dayRef: number) => {
    setEnableDaysButton(false);
    if (dayRef == 0) {
      setDateLocal(
        new Date(
          new Date().toLocaleString('en-US', { timeZone: 'America/Sao_Paulo' }),
        ),
      );
      setTimeout(() => {
        prepareOrders(orders);
        setEnableDaysButton(true);
      }, 1000);
      prepareOrders(orders);
    } else {
      // pega a data atual e soma ou subtrai a quantidade de dias
      const newDate = new Date(
        dateLocal.getTime() + dayRef * 24 * 60 * 60 * 1000,
      );
      setDateLocal(newDate);
      setTimeout(() => {
        prepareOrders(orders);
        setEnableDaysButton(true);
      }, 1000);
      prepareOrders(orders);
    }
  };

  const getWeekRange = (date: any) => {
    const dayOfWeek = date.getDay();

    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - dayOfWeek);
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(date);
    endOfWeek.setDate(date.getDate() + (6 - dayOfWeek));
    endOfWeek.setHours(23, 59, 59, 999);

    return {
      startOfWeek,
      endOfWeek,
    };
  };

  const optionVendasDia = {
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      data: [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
      ],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: dailyData,
        type: 'bar',
      },
    ],
  };

  const optionVendasSemana = {
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      data: [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado',
      ],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: weekData,
        type: 'bar',
      },
    ],
  };

  const optionVendasMes = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['Vendas'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      data: [
        'dia 01',
        'dia 02',
        'dia 03',
        'dia 04',
        'dia 05',
        'dia 06',
        'dia 07',
        'dia 08',
        'dia 09',
        'dia 10',
        'dia 11',
        'dia 12',
        'dia 13',
        'dia 14',
        'dia 15',
        'dia 16',
        'dia 17',
        'dia 18',
        'dia 19',
        'dia 20',
        'dia 21',
        'dia 22',
        'dia 23',
        'dia 24',
        'dia 25',
        'dia 26',
        'dia 27',
        'dia 28',
        'dia 29',
        'dia 30',
        'dia 31',
      ],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: monthData,
        type: 'line',
      },
    ],
  };

  const optionTicketMedioAno = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['Ticket médio'],
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      data: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: monthTicketData,
        type: 'line',
      },
    ],
  };

  const optionFaturamentoAno = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['Faturamento'],
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      data: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: faturamentoData,
        type: 'line',
      },
    ],
  };

  useEffect(() => {
    const getOrders = async () => {
      if (typeof document == 'undefined') return;
      if (typeof window == 'undefined') return;

      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/orders/getAllOrders', {
          params: {
            dominio: domain,
            accessToken:
              'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
          },
        })
        .then((response: any) => {
          prepareOrders(response.data.data);
          setOrders(response.data.data);
        })
        .catch((error) => {
          if (error.response.data.code != 4000) {
            if (error.response.data.code) {
              Swal.fire({
                position: 'top',
                icon: 'error',
                title: `ERRO - ${error.response.data.code}`,
                text: `${error.response.data.error}`,
              });
            }
          }
        });
    };

    getOrders();
  }, []);

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '' };
      decodeJwt = jwtDecode(token);

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
  }, []);

  const prepareOrders = (orders: any[]) => {
    const pedidosDoDia: any[] = [];
    const pedidosDaSemana: any[] = [];
    const pedidosDoMes: any[] = [];
    const ticketsDoAno: any[] = [];

    const weekRange = getWeekRange(dateLocal);

    orders.forEach((order: any) => {
      const dataPedido = new Date(order.data_pedido);
      if (dataPedido.getFullYear() === dateLocal.getFullYear()) {
        if (dataPedido.getMonth() === dateLocal.getMonth()) {
          if (dataPedido.getDate() === dateLocal.getDate()) {
            pedidosDoDia.push(order);
          }
          if (
            dataPedido >= weekRange.startOfWeek &&
            dataPedido <= weekRange.endOfWeek
          ) {
            pedidosDaSemana.push(order);
          }
          pedidosDoMes.push(order);
        }
        if (order?.status == 'aprovado') {
          ticketsDoAno.push(order);
        }
      }
    });

    prepareGraphs(pedidosDoDia, pedidosDaSemana, pedidosDoMes, ticketsDoAno);
  };

  const prepareGraphs = (
    pedidosDoDia: any[],
    pedidosDaSemana: any[],
    pedidosDoMes: any[],
    ticketsDoAno: any[],
  ) => {
    const dailyData = Array(24).fill(0);
    const weekData = Array(7).fill(0);
    const monthData = Array(31).fill(0);
    const faturamentoData = Array(12).fill(0);
    const monthTicketData = Array(12).fill(0);
    const orders = Array(12).fill(0);

    pedidosDoDia.forEach((order: any) => {
      const dataPedido = new Date(order.data_pedido);
      dailyData[dataPedido.getHours()]++;
    });

    pedidosDaSemana.forEach((order: any) => {
      const dataPedido = new Date(order.data_pedido);
      weekData[dataPedido.getDay()]++;
    });

    pedidosDoMes.forEach((order: any) => {
      const dataPedido = new Date(order.data_pedido);
      monthData[dataPedido.getDate() - 1]++;
    });

    ticketsDoAno.forEach((order: any) => {
      const dataPedido = new Date(order.data_pedido);
      const monthIndex = dataPedido.getMonth();
      faturamentoData[monthIndex] += order.valor;
      orders[monthIndex]++;
      monthTicketData[monthIndex] += order.valor;
    });

    for (let i = 0; i < 12; i++) {
      if (orders[i] > 0) {
        monthTicketData[i] /= orders[i];
      } else {
        monthTicketData[i] = 0;
      }
    }

    // Supondo que você tenha um mecanismo para definir os dados nos gráficos
    setDailyData(dailyData);
    setWeekData(weekData);
    setMonthData(monthData);
    setFaturamentoData(faturamentoData);
    setMonthTicketData(monthTicketData);

    setLoading(false);
  };

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="flex flex-col w-full h-full bg-orange-50">
            <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
              <button onClick={() => navigate('/')} className="flex flex-row">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
              <button
                className="block md:hidden lg:hidden"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.9em"
                  height="1.9em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="black"
                    d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                  />
                </svg>
              </button>
              <DashboardDrawer
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                page="financeiro"
                nivel={'1'}
              />
            </div>
            <div className="flex flex-col lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="financeiro" />
              </div>
              <div className="flex flex-col w-full h-screen max-h-screen overflow-y-scroll p-2 bg-white rounded-lg shadow-lg">
                <h1 className="text-2xl w-full text-center">
                  Resultado de análises
                </h1>
                {loading ? (
                  <></>
                ) : (
                  <>
                    {enableDaysButton ? (
                      <>
                        <div className="w-full flex flex-row p-2 my-1 justify-between items-center">
                          <button onClick={() => handleChangeDateLocal(-1)} className="p-2 w-fit h-fit bg-green-500 text-white font-medium rounded-lg hover:bg-green-600 transition ease-in-out">
                            Dia anterior
                          </button>
                          <button onClick={() => handleChangeDateLocal(0)} className="p-2 w-fit h-fit bg-stone-200 border border-stone-700 font-medium rounded-lg hover:bg-stone-300 transition ease-in-out">
                            Voltar ao dia atual
                          </button>
                        </div>
                        <h1>Data de referência: {masks.formatISODateToBrazilian(dateLocal.toISOString())}</h1>
                      </>
                    ) : (
                      <>
                        <div className="w-full flex flex-row p-2 my-1 justify-between items-center">
                          <button className="p-2 w-fit h-fit bg-green-800 text-white font-medium rounded-lg">
                            Dia anterior
                          </button>
                          <button className="p-2 w-fit h-fit bg-stone-500 border border-stone-700 font-medium rounded-lg">
                            Voltar ao dia atual
                          </button>
                        </div>
                      </>
                    )}
                    <div className="flex flex-col w-full p-2 my-1 border rounded-xl">
                      <h1>Pedidos durante o dia:</h1>
                      <ReactEcharts option={optionVendasDia} />
                    </div>
                    <div className="flex flex-col w-full p-2 my-1 border rounded-xl">
                      <h1>Pedidos durante a semana:</h1>
                      <ReactEcharts option={optionVendasSemana} />
                    </div>
                    <div className="flex flex-col w-full p-2 my-1 border rounded-xl">
                      <h1>Pedidos durante o mês:</h1>
                      <ReactEcharts option={optionVendasMes} />
                    </div>
                    <div className="flex flex-col w-full p-2 my-1 border rounded-xl">
                      <h1>Ticket médio ao longo do ano:</h1>
                      <ReactEcharts option={optionTicketMedioAno} />
                    </div>
                    <div className="flex flex-col w-full p-2 my-1 border rounded-xl">
                      <h1>Faturamento ao longo do ano:</h1>
                      <ReactEcharts option={optionFaturamentoAno} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
