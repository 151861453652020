/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

const Hotbar = ({ buttons, onSelect }: { buttons: any[], onSelect: (button: any) => void }) => {
    const [selected, setSelected] = React.useState(buttons[0]);
  
    const handleClick = (button: any) => {
      setSelected(button);
      onSelect(button);
    };

  return (
    <div className="flex justify-between max-w-full overflow-x-scroll lg:overflow-hidden w-full montserrat-500">
      {buttons.map((button, index) => (
        <div
          key={index}
          className="relative flex flex-col items-center cursor-pointer"
          onClick={() => handleClick(index)}
        >
          <div
            className={`text-xs lg:text-sm py-1 px-2 ${
              selected === index ? 'text-black' : ''
            }`}
          >
            {button}
          </div>
          {selected === index && (
            <div className="w-full h-0.5 rounded-lg bg-green-500 mt-1"></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Hotbar;
