/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
import { useEffect, useState } from 'react';
import { Box, Modal, TextField, Typography } from '@mui/material';
import api from '../generalComponents/api';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Autorizacao from '../generalComponents/Autorizacao';
import Swal from 'sweetalert2';
import DashboardDrawer from '../generalComponents/DashboardDrawer';

//accessToken: 'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557'

export default function RastreioPorRifa() {
  const navigate = useNavigate();
  const [termoPesquisa, setTermoPesquisa] = useState('');
  const [tracks, setTracks] = useState([] as object[]);
  const [autorizacao, setAutorizacao] = useState(false);
  const [filteredTracks, setFilteredTracks] = useState([] as object[]);
  const [newTrackName, setNewTrackName] = useState('');
  const [trackName, setTrackName] = useState('');
  const [link, setLink] = useState('');
  const [domain, setDomain] = useState('');
  const [nivel, setNivel] = useState('1');
  //modal
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  // controle do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const getClientInfo = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/client/getAccountData', {
        params: {
          dominio: domain,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
        },
      })
      .then((response) => {
        console.log(response.data);
        setNivel(response.data.nivel);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '' };
      decodeJwt = jwtDecode(token);

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
    getClientInfo();
  }, []);

  useEffect(() => {
    handleGetAllTracks();
  }, []);

  const handleGetAllTracks = async () => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }

    const domain = window.location.hostname;
    setDomain(domain);

    const pathSegments = location.pathname.split('/');
    const segment = pathSegments[pathSegments.length - 1];
    setLink(segment);

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/tracks/getTracksByLink', {
        params: {
          dominio: domain,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
          link: segment,
        },
      })
      .then((response) => {
        console.log(response.data);
        setTracks(response.data);
        setFilteredTracks(response.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  const handleDeleteTrack = async (trackId: string) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não será capaz de reverter isso!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, deletar!',
    }).then(async (result) => {
      if (!result.isConfirmed) {
        return;
      } else {
        if (typeof document == 'undefined') {
          return;
        }
        if (typeof window == 'undefined') {
          return;
        }

        const domain = window.location.hostname;

        const response = await api
          .get('/cookie')
          .catch((error) => console.log(error));
        const _csrf = response?.data?.csrfToken;
        api.defaults.headers.common['X-CSRF-Token'] = _csrf;

        await api
          .delete('/tracks/deleteTrack', {
            params: {
              dominio: domain,
              accessToken:
                'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
              id: trackId,
            },
          })
          .then((response) => {
            console.log(response.data);
            Swal.fire({
              position: 'top',
              icon: 'success',
              title: 'Rastreador deletado com sucesso',
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              position: 'top',
              icon: 'error',
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`,
            });
          });
      }
    });
  };

  const generateNewTrackLink = () => {
    if (nivel == '1') {
      setNewTrackName('');
      Swal.fire({
        position: 'top',
        icon: 'error',
        title: 'Você não tem permissão para criar rastreadores',
        text: 'Atualize seu plano para ter acesso a essa funcionalidade',
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        return;
      });
      return;
    }
    //remove spaces from string
    const trackWithoutSpaces = newTrackName.replace(/\s/g, '');
    //code with 6 numeric digits
    const code = Math.floor(100000 + Math.random() * 900000);
    const stringConcat = `${trackWithoutSpaces}${code}`;
    console.log(stringConcat);
    setTrackName(stringConcat);
  };

  useEffect(() => {
    if (trackName != '') {
      setOpen(true);
    }
  }, [trackName]);

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermoPesquisa(event.target.value);
    setFilteredTracks(filterTrack(event.target.value));
  };

  const filterTrack = (term: string) => {
    return tracks.filter((track: any) => {
      return track.id.toLowerCase().includes(term.toLowerCase());
    });
  };

  const handleCopyLink = () => {
    const domain = window.location.hostname;

    navigator.clipboard.writeText(
      `https://${domain}/singleraffle/${link}?rastreio=${trackName}`,
    );
    Swal.fire({
      position: 'top',
      icon: 'success',
      title: 'Link copiado com sucesso',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const handleCopyLinkButton = (link: string) => {
    navigator.clipboard.writeText(link);
    Swal.fire({
      position: 'top',
      icon: 'success',
      title: 'Link copiado',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: '20px',
  };

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="w-full flex flex-row justify-between">
                  <h1>Copie seu link e divulgue</h1>
                  <button onClick={() => setOpen(false)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.2em"
                      height="1.2em"
                      viewBox="0 0 48 48"
                    >
                      <path
                        fill="none"
                        stroke="black"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                        stroke-width="4"
                        d="m8 8l32 32M8 40L40 8"
                      />
                    </svg>
                  </button>
                </div>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div
                  onClick={() => handleCopyLink()}
                  className="w-full h-full flex flex-col p-2"
                >
                  <h1>Clique em qualquer lugar para copiar o link</h1>
                  <h1 className="montserrat-500 mt-1 text-xs">{`https://${domain}/singleraffle/${link}?rastreio=${trackName}`}</h1>
                </div>
              </Typography>
            </Box>
          </Modal>
          <div className="flex flex-col w-full h-full bg-orange-50">
            <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
              <button onClick={() => navigate('/')} className="flex flex-row">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
              <button
                className="block md:hidden lg:hidden"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.9em"
                  height="1.9em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="black"
                    d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                  />
                </svg>
              </button>
              <DashboardDrawer
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                page="sorteios"
                nivel={'1'}
              />
            </div>
            <div className="flex flex-col lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="rastreadores" />
              </div>
              <div className="flex flex-col w-full h-screen max-h-screen bg-white rounded-lg shadow-lg">
                <div className="flex flex-col w-full max-w-full">
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                    className="mr-2"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Pesquisar pelo código de rastreio"
                      variant="outlined"
                      color="warning"
                      value={termoPesquisa}
                      onChange={handleFilter}
                    />
                  </Box>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { m: 1, width: '98%' },
                      width: '100%',
                    }}
                    noValidate
                    autoComplete="off"
                    className="mr-2"
                  >
                    <TextField
                      id="outlined-basic"
                      label="Insira o nome do novo rastreador"
                      variant="outlined"
                      color="success"
                      value={newTrackName}
                      onChange={(e) => setNewTrackName(e.target.value)}
                    />
                  </Box>
                  <button
                    onClick={() => generateNewTrackLink()}
                    className="w-full h-fit p-2 bg-green-500 rounded-xl text-white font-medium hover:bg-green-600 trasition ease-in-out"
                  >
                    Gerar novo rastreador
                  </button>
                </div>
                <div className="flex flex-col w-full h-full p-2 overflow-y-scroll">
                  {Array.isArray(filteredTracks) &&
                    filteredTracks?.map((track: any) =>
                      track?.trackId != '' &&
                      track?.trackId != null &&
                      track?.trackId != undefined ? (
                        <>
                          <div
                            className="flex flex-row bg-gray-100 justify-between w-full p-3 my-1 rounded-xl"
                            key={track.trackId}
                          >
                            <div className="flex flex-col">
                              <span>{track.trackId}</span>
                              <span className="hidden md:block lg:block">{`https://${track.dominio}/singleraffle/${track.link}?rastreio=${track.trackId}`}</span>
                              <button
                                onClick={() =>
                                  handleCopyLinkButton(
                                    `https://${track.dominio}/singleraffle/${track.link}?rastreio=${track.trackId}`,
                                  )
                                }
                                className="w-fit h-fit px-4 py-2 rounded-lg text-white bg-green-500 hover:bg-green-600 transition ease-in-out"
                              >
                                Copiar link
                              </button>
                            </div>
                            <div className="flex flex-col">
                              <span>
                                Faturamento: R${' '}
                                {parseFloat(track.valor).toFixed(2)}
                              </span>
                              <span>Números vendidos: {track.quantidade}</span>
                              <button
                                onClick={() => handleDeleteTrack(track.trackId)}
                                className="bg-red-600 text-white font-medium p-2 rounded-lg hover:bg-red-700 transition ease-in-out"
                              >
                                Deletar rastreador
                              </button>
                            </div>
                          </div>
                        </>
                      ) : null,
                    )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
