"use client"
import { useEffect } from "react"

export default function Session() {
    useEffect(() => {
        console.log("aqui")  // Este log deve aparecer no console
        if (typeof document === 'undefined' || typeof window === 'undefined') {
            return;
        }
        console.log("set item")  // Este log deve aparecer no console antes do redirecionamento
        localStorage.setItem('appSession', new URLSearchParams(window.location.search).get('token') || "")
        console.log("redirect")  // Este log deve aparecer no console antes do redirecionamento
        location.href = '/'
    }, [])

    return (
        <></>
    )
}
