import { useNavigate } from "react-router-dom";

export default function Autorizacao() {
    const navigate = useNavigate()
  return (
    <>
      <div className="w-screen h-screen bg-white flex flex-col justify-center items-center text-center">
        <div className="bg-red-100 w-1/2 h-1/2 flex flex-col justify-center items-center m-2 p-2 border border-red-400">
          <h1 className="text-3xl font-bold m-1">Acesso Negado</h1>
          <p className="text-lg m-1">
            Você não tem permissão para acessar esta página.
          </p>
          <p className="text-md m-1">
            Faça login com uma conta autorizada para acessar esta página.
          </p>
          <a
            className="m-4 p-2 rounded-lg text-white font-bold bg-green-500"
            href="https://www.digitallucky.com.br/signup"
          >
            Login
          </a>
        </div>
      </div>
    </>
  );
}