import React, { useEffect, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';

const imageUrls = [
  'https://images.pexels.com/photos/3876635/pexels-photo-3876635.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  'https://images.pexels.com/photos/7080451/pexels-photo-7080451.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
  'https://images.pexels.com/photos/7083895/pexels-photo-7083895.jpeg',
];

export default function View() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideInterval = useRef<NodeJS.Timeout | null>(null);
  const theme = useTheme();

  useEffect(() => {
    startSlideShow();
    return () => stopSlideShow();
  }, []);

  const startSlideShow = () => {
    slideInterval.current = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % imageUrls.length);
    }, 3000);
  };

  const stopSlideShow = () => {
    if (slideInterval.current) {
      clearInterval(slideInterval.current);
    }
  };

  return (
    <Box
      className=" mx-1 w-full lg:w-3/12 small-smartphone-screen border border-gray-300 rounded-3xl bg-white max-w-full hidden lg:flex flex-col"
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: '513px', // Defina a altura desejada do carousel aqui
      }}
    >
      <Box
        sx={{
          display: 'flex',
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeInOut,
          }),
          transform: `translateX(-${currentSlide * 100}%)`,
        }}
      >
        {imageUrls.map((url, index) => (
          <Box key={index} sx={{ minWidth: '100%', flexShrink: 0 }}>
            <img
              src={url}
              alt={`Slide ${index + 1}`}
              style={{ width: '100%', height: '513px', objectFit: 'cover' }} // Ajuste aqui
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
