import './App.css';
import Routers from './Routers.js';
import TagManager from 'react-gtm-module';
import { useEffect, useState } from 'react';
import api from './components/generalComponents/api';

function App() {
  const [client, setClient] = useState({} as any);

  const getClientInfo = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const domain = window.location.hostname;

    const response = await api
      .get('/client/getAccountConfig', { params: { dominio: domain } })
      .then((response) => {
        console.log(response.data);
        setClient(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getClientInfo();
  }, []);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: client?.analytics?.googleAnalytics
    }
    TagManager.initialize(tagManagerArgs);
  }, [client]);

  return <Routers />;
}

export default App;
