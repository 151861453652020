import Swal from 'sweetalert2';
import HeaderMain from '../generalComponents/HeaderMain';
import api from '../generalComponents/api';
import FaqCard from './components/FaqCard';
import { useState, useEffect } from 'react';

export default function Perguntas() {
  const [perguntas, setPerguntas] = useState([] as any);

  useEffect(() => {
    const getFaq = async () => {
      if (typeof document == 'undefined') return;
      if (typeof window == 'undefined') return;

      const domain = window.location.hostname;

      const response = await api
        .get('/cookie')
        .catch((error) => console.log(error));
      const _csrf = response?.data?.csrfToken;
      api.defaults.headers.common['X-CSRF-Token'] = _csrf;

      await api
        .get('/client/getAccountConfig', { params: { dominio: domain } })
        .then((response) => {
          if (Array.isArray(response.data.perguntas)) {
            setPerguntas(response.data.perguntas);
          }
        })
        .catch((error) => {});
    };

    getFaq();
  }, []);

  return (
    <>
      <div className="w-full min-h-screen h-full flex flex-col">
        <HeaderMain />
        <div className="flex flex-col w-full h-full p-2">
          {perguntas?.map((pergunta: any, id: number) => {
            return <FaqCard key={id} pergunta={pergunta} />;
          })}
          <div className="mt-2 w-full justify-center items-center text-center">
            Alguma outra dúvida? Entre em contato conosco clicando{' '}
            <a href="" className="text-green-700 font-medium underline">
              aqui!
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
