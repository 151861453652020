import { useEffect, useState } from 'react';
import { Masks } from '../Masks/masks';
import { Box, TextField } from '@mui/material';
import api from '../generalComponents/api';
import { jwtDecode } from 'jwt-decode';
import Autorizacao from '../generalComponents/Autorizacao';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import DashboardSideTools from '../generalComponents/DashboardSideTools';
import DashboardDrawer from '../generalComponents/DashboardDrawer';

export default function NewWinner() {
  const masks = new Masks();
  const navigate = useNavigate();

  //informações da rifa
  const [titulo, setTitulo] = useState('');
  const [dataSorteio, setDataSorteio] = useState('');
  const [imagem, setImagem] = useState([] as any);
  const [premios, setPremios] = useState([] as any);
  const [sidePremios, setSidePremios] = useState([] as any);
  const [modalidade, setModalidade] = useState('loteria');
  //informações do ganhador
  const [ganhador, setGanhador] = useState('Sem ganhador definido!');
  const [quantidadeCotas, setQuantidadeCotas] = useState(1000000);
  const [numeroTituloGanhador, setNumeroTituloGanhador] = useState('');
  const [ganhadorInfo, setGanhadorInfo] = useState([] as any);
  const [exibirResultado, setExibirResultado] = useState(false);
  const [exibirTelefone, setExibirTelefone] = useState(false);
  const [autorizacao, setAutorizacao] = useState(false);
  const [sideWinnerNumber, setSideWinnerNumber] = useState('');
  const [sideWinners, setSideWinners] = useState([] as any);
  const [exibirInfoPessoalSideWinners, setExibirInfoPessoalSideWinners] =
    useState(false);
  //states auxiliares
  const [enableWinnerButton, setEnableWinnerButton] = useState(true);
  const [enableSideWinnerButton, setEnableSideWinnerButton] = useState(true);
  const [isGirando, setIsGirando] = useState(false);
  const [numeroGiro, setNumeroGiro] = useState('Boa sorte!');
  //controlador do drawer
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const handlerSubmitNewWinner = (
    e: React.FormEvent<HTMLButtonElement>,
    numero: string,
  ) => {
    e.preventDefault();
    if (numero.trim() === '') {
      alert('Número do título do ganhador é obrigatório!');
      return;
    }
    setNumeroTituloGanhador(
      masks.setZeroLeft(numero, (quantidadeCotas - 1).toString().length),
    );
    handleNewWinner(numero.trim());
  };

  const handleShowPhone = () => {
    setExibirTelefone(!exibirTelefone);
  };

  useEffect(() => {
    if (typeof document == 'undefined') {
      return;
    }
    if (typeof window == 'undefined') {
      return;
    }
    const authorization = async () => {
      const token = localStorage.getItem('appSession') || '';
      if (token == '') {
        setAutorizacao(false);
        return;
      }
      let decodeJwt = { email: '' };
      decodeJwt = jwtDecode(token);

      await api
        .get('/client/authorizate', { params: { email: decodeJwt.email } })
        .then((response) => {
          setAutorizacao(true);
          handleGetRaffle();
          handleGetWinner();
        })
        .catch((error) => {
          setAutorizacao(false);
        });
    };
    authorization();
  }, []);

  const handleGetRaffle = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const dominio = window.location.hostname;
    const pathSegments = location.pathname.split('/');
    const link = pathSegments[pathSegments.length - 1];

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/raffles/getRaffle', {
        params: {
          dominio,
          link: link,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj12312394a8945557',
        },
      })
      .then((response) => {
        setQuantidadeCotas(response.data[0].qtdCotas);
        setTitulo(response.data[0].nome);
        setDataSorteio(
          dayjs(response.data[0].dataSorteio)
            .tz('America/Sao_Paulo')
            .format('DD/MM/YYYY HH:mm'),
        );
        setImagem(response.data[0].imagens);
        setPremios(response.data[0].premios);
        setModalidade(response.data[0]?.modalidade);
      })
      .catch((error) => {
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  useEffect(() => {
    if (premios.length > 0) {
      const primeiroPremio = premios.shift();
      setSidePremios(premios);
    }
  }, [premios]);

  const handleGetWinner = async () => {
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const dominio = window.location.hostname;
    const pathSegments = location.pathname.split('/');
    const link = pathSegments[pathSegments.length - 1];

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    await api
      .get('/winners/getWinner', {
        params: {
          dominio,
          link: link,
          accessToken:
            'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
        },
      })
      .then((response) => {
        if (
          response?.data === null ||
          response?.data === undefined ||
          response?.data === ''
        ) {
          setGanhador('Sem ganhador definido!');
          return;
        }
        setGanhador(response?.data?.nome);
        setGanhadorInfo(response?.data);
        setNumeroTituloGanhador(response?.data?.cota);
        setExibirResultado(!exibirResultado);
        setIsGirando(true);
        setNumeroGiro(response?.data?.cota);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNewWinner = async (numeroGanhador: string) => {
    setEnableWinnerButton(false);
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const dominio = window.location.hostname;
    const pathSegments = location.pathname.split('/');
    const link = pathSegments[pathSegments.length - 1];

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const data = {
      dominio,
      link: link,
      cota: masks.setZeroLeft(
        numeroGanhador,
        (quantidadeCotas - 1).toString().length,
      ),
      accessToken:
        'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
    };

    await api
      .post('/winners/newWinner', data)
      .then((response) => {
        setEnableWinnerButton(true);
        setGanhador(response?.data?.nome);
        setGanhadorInfo(response?.data);
        setExibirResultado(!exibirResultado);
      })
      .catch((error) => {
        setEnableWinnerButton(true);
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          }).then((confirmation) => {
            if (confirmation.isConfirmed) {
              window.location.reload();
            }
          });
        }
      });
  };

  const handleDeleteWinner = async () => {
    setEnableWinnerButton(false);
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const dominio = window.location.hostname;
    const pathSegments = location.pathname.split('/');
    const link = pathSegments[pathSegments.length - 1];

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const data = {
      dominio,
      link: link,
      accessToken:
        'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
    };

    await api
      .delete('/winners/deleteWinner', { params: data })
      .then((response) => {
        setEnableWinnerButton(true);
        Swal.fire({
          icon: 'success',
          title: 'Ganhador deletado!',
          text: 'O ganhador foi deletado com sucesso!',
          confirmButtonColor: '#3085d6',
        }).then(() => {
          window.location.reload();
        });
        setGanhador('Sem ganhador definido!');
        setExibirResultado(!exibirResultado);
      })
      .catch((error) => {
        setEnableWinnerButton(true);
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  const handleSubmitSideWinner = (e: React.FormEvent<HTMLButtonElement>) => {
    if (sideWinners.length >= sidePremios.length) {
      Swal.fire({
        icon: 'warning',
        title: 'Erro',
        text: 'Todos os prêmios secundários já foram sorteados!',
        confirmButtonColor: '#fcba03',
      });
      return;
    }
    if (sideWinnerNumber.trim() === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Erro',
        text: 'Número do título do ganhador é obrigatório!',
        confirmButtonColor: '#fcba03',
      });
      return;
    }
    if (parseFloat(sideWinnerNumber) > quantidadeCotas) {
      Swal.fire({
        icon: 'warning',
        title: 'Erro',
        text: 'Número do título do ganhador não pode ser maior que a quantidade de cotas!',
        confirmButtonColor: '#fcba03',
      });
      return;
    }

    const number = masks.setZeroLeft(
      sideWinnerNumber,
      (quantidadeCotas - 1).toString().length,
    );

    sideWinners.push(number);
    setSideWinnerNumber('');
  };

  const handleSetSideWinners = async () => {
    setEnableSideWinnerButton(false);
    if (typeof document == 'undefined') return;
    if (typeof window == 'undefined') return;

    const dominio = window.location.hostname;
    const pathSegments = location.pathname.split('/');
    const link = pathSegments[pathSegments.length - 1];

    const response = await api
      .get('/cookie')
      .catch((error) => console.log(error));
    const _csrf = response?.data?.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = _csrf;

    const data = {
      dominio,
      link: link,
      sideWinners: sideWinners,
      accessToken:
        'rMRWYnD1vvy8TD61Yj9ClCvIZpB2PIg76XOLwd9sZNNJUh28QuOwUotj266294a8945557',
    };

    await api
      .put('/winners/updateSideWinners', data)
      .then((response) => {
        setEnableSideWinnerButton(true);
        Swal.fire({
          icon: 'success',
          title: 'Ganhadores secundários salvos!',
          text: 'Os ganhadores secundários foram salvos com sucesso!',
          timer: 1500,
          timerProgressBar: true,
          confirmButtonColor: '#3085d6',
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        setEnableSideWinnerButton(true);
        if (error.response.data.code) {
          Swal.fire({
            position: 'top',
            icon: 'error',
            title: `ERRO - ${error.response.data.code}`,
            text: `${error.response.data.error}`,
          });
        }
      });
  };

  const handleGiroDaSorte = () => {
    setIsGirando(true); // Inicia a animação de sorteio

    let count = 5;
    const totalIntervals = 40; // Quantidade de vezes que números aleatórios serão exibidos
    const finalTimeout = (totalIntervals + count) * 1000; // Tempo total até o sorteio final

    // Exibe o contador regressivo de 5 a 0
    const interval = setInterval(() => {
      setNumeroGiro(count.toString());
      count--;

      if (count < 0) {
        clearInterval(interval); // Para o contador regressivo

        // Sorteia números aleatórios
        const randomInterval = setInterval(() => {
          setNumeroGiro(Math.floor(Math.random() * quantidadeCotas).toString()); // Atualiza com números aleatórios
        }, 100);

        // Para o sorteio após determinado tempo
        setTimeout(() => {
          clearInterval(randomInterval); // Para os números aleatórios
          // Exibe o número sorteado final
          const numeroFinal = masks.setZeroLeft(
            Math.floor(Math.random() * quantidadeCotas).toString(),
            (quantidadeCotas - 1).toString().length,
          );
          setNumeroGiro(numeroFinal);
          setNumeroTituloGanhador(numeroFinal);
          const syntheticEvent = {
            preventDefault: () => {},
          } as React.FormEvent<HTMLButtonElement>;
          handlerSubmitNewWinner(syntheticEvent, numeroFinal.toString()); // Chama a função de definir novo ganhador
        }, totalIntervals * 100); // Após 10 iterações (100ms cada)
      }
    }, 1000); // Contagem regressiva de 5 a 0
  };

  return (
    <>
      {!autorizacao ? (
        <>
          <Autorizacao />
        </>
      ) : (
        <>
          <div className="flex flex-col w-full h-full bg-orange-50">
            <div className="flex flex-row justify-between w-screen p-5 hover:shadow-inner rounded-lg">
              <button onClick={() => navigate('/')} className="flex flex-row">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="2em"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fill="none"
                      stroke="#00662c"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m7.405 13.5l-2.905-3l2.905-3m-2.905 3h9m-6-7l8 .002c1.104.001 2 .896 2 2v9.995a2 2 0 0 1-2 2l-8 .003"
                    />
                  </svg>
                </span>
                <span className="text-green-700 montserrat-500 m-1">
                  Voltar
                </span>
              </button>
              <button
                className="block md:hidden lg:hidden"
                onClick={() => setIsOpenDrawer(!isOpenDrawer)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.9em"
                  height="1.9em"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="black"
                    d="M2 4.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m0 5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1h-15a.5.5 0 0 1-.5-.5m.5 4.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1z"
                  />
                </svg>
              </button>
              <DashboardDrawer
                isOpen={isOpenDrawer}
                setIsOpen={setIsOpenDrawer}
                page="sorteios"
                nivel={'1'}
              />
            </div>
            <div className="flex flex-col lg:flex-row w-full h-full p-2">
              <div className="hidden lg:flex md:flex flex-col min-w-96">
                <DashboardSideTools page="sorteios" />
              </div>
              <div className="flex flex-col w-full h-screen max-h-screen overflow-y-scroll p-2 bg-white rounded-lg shadow-lg">
                <h1 className="text-2xl w-full text-center">
                  Definir novo ganhador
                </h1>
                {modalidade === 'loteria' ? (
                  <>
                    <p className="p-2 bg-amber-100 rounded-xl text-amber-900">
                      Escreva o número na área correspondente para definir um
                      ganhador. O ganhador somente será definido se esse número
                      já estiver sido comprado! Portanto, antes de definir o
                      ganhador, tenha certeza de que sua ação tenha sido 100%
                      vendida!
                    </p>
                  </>
                ) : (
                  <>
                    <p className="p-2 bg-amber-100 rounded-xl text-amber-900">
                      Clique no botão para GIRAR UM NÚMERO ALEATÓRIO e o sistema
                      definirá, automaticamente, um ganhador para você! <br />
                      <br />O sistema gerará um número dentro do intervalo
                      definido do sorteio! <br />
                      <br />
                      Por exemplo, sorteio de 100 mil cotas: intervalo entre
                      00000 e 99999. <br />
                      <br />
                      Se o número não tiver sido vendido, o sistema informará e
                      você poderá tentar novamente!
                    </p>
                  </>
                )}
                <div className="mt-4 flex flex-col md:flex-row lg:flex-row w-full justify-center items-center lg:justify-start">
                  <img
                    className="border-2 border-gray-300 rounded-xl w-96 h-fit object-contain"
                    src={imagem[0]}
                    alt=""
                  />
                  <div className="w-full flex flex-col p-2 justify-center items-center md:justify-normal md:items-start lg:justify-normal lg:items-start">
                    <h1 className="text-3xl font-medium my-1">{titulo}</h1>
                    <h1 className="my-1">{dataSorteio}</h1>
                    <h1 className="my-1 montserrat-500">{ganhador}</h1>
                    {modalidade === 'loteria' ? (
                      <>
                        {!exibirResultado ? (
                          <>
                            <div className="w-full flex flex-row items-center">
                              <Box
                                component="form"
                                sx={{
                                  '& > :not(style)': { my: 1, width: '98%' },
                                  width: '100%',
                                }}
                                noValidate
                                autoComplete="off"
                              >
                                <TextField
                                  id="outlined-basic"
                                  label="Número do título ganhador *"
                                  variant="outlined"
                                  color="success"
                                  value={numeroTituloGanhador}
                                  onChange={(e) =>
                                    setNumeroTituloGanhador(
                                      masks.removeNonNumericChars(
                                        e.target.value,
                                      ),
                                    )
                                  }
                                />
                              </Box>
                              {enableWinnerButton ? (
                                <>
                                  <button
                                    className="w-fit h-fit p-2 bg-green-500 text-white rounded-xl m-2 hover:bg-green-600  transition ease-in-out"
                                    onClick={(e) =>
                                      handlerSubmitNewWinner(
                                        e,
                                        numeroTituloGanhador,
                                      )
                                    }
                                  >
                                    Enviar
                                  </button>
                                </>
                              ) : (
                                <>
                                  <>
                                    <button className="w-fit h-fit p-2 bg-green-600 text-white rounded-xl m-2">
                                      Procurando ganhador...
                                    </button>
                                  </>
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {enableWinnerButton ? (
                              <>
                                <div className="w-full flex flex-row items-center">
                                  <button
                                    onClick={() => handleDeleteWinner()}
                                    className="w-full p-2 text-white font-bold bg-red-600 rounded-xl hover:bg-red-700 transition ease-in-out"
                                  >
                                    Deletar ganhador
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="w-full flex flex-row items-center">
                                  <button className="w-full p-2 text-white font-bold bg-red-700 rounded-xl">
                                    Deletando...
                                  </button>
                                </div>
                              </>
                            )}
                          </>
                        )}
                        {exibirResultado ? (
                          <>
                            <div className="my-1">
                              Parabéns, <strong>{ganhador}</strong>! Você é o
                              ganhador do sorteio com a cota número{' '}
                              <strong>{numeroTituloGanhador}</strong>!
                            </div>
                            <button
                              className="my-1 p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-xl"
                              onClick={() => handleShowPhone()}
                            >
                              Mostrar informações pessoais
                            </button>
                            <button
                              onClick={() =>
                                navigate(`/checkout/${ganhadorInfo?.orderId}`)
                              }
                              className="my-1 p-2 bg-green-500 hover:bg-green-600 text-white rounded-xl"
                            >
                              Ir para o pedido (Contém as informações pessoais
                              do ganhador!)
                            </button>
                            {exibirTelefone ? (
                              <div className="my-1">
                                Nome do ganhador: <strong>{ganhador}</strong>
                                <br />
                                Telefone do ganhador:{' '}
                                <strong>{ganhadorInfo?.telefone}</strong>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </>
                        ) : (
                          <>
                            <div></div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="w-full flex flex-col items-center justify-center text-center p-1">
                          {!isGirando ? (
                            <>
                              <button
                                onClick={() => handleGiroDaSorte()}
                                className="p-2 w-full rounded-lg font-bold text-2xl text-white bg-green-500 hover:bg-green-600 transition ease-in-out"
                              >
                                Giro da sorte!
                              </button>
                            </>
                          ) : (
                            <>
                              <div className="w-full flex flex-col items-center justify-center text-center">
                                <h1 className="p-2 text-3xl">{numeroGiro}</h1>
                                {exibirResultado ? (
                                  <>
                                    <div className="flex flex-col w-full items-start justify-start text-start">
                                      <div className="my-1">
                                        Parabéns, <strong>{ganhador}</strong>!
                                        Você é o ganhador do sorteio com a cota
                                        número{' '}
                                        <strong>{numeroTituloGanhador}</strong>!
                                      </div>
                                      <button
                                        className="my-1 p-2 bg-blue-500 hover:bg-blue-600 text-white rounded-xl"
                                        onClick={() => handleShowPhone()}
                                      >
                                        Mostrar informações pessoais
                                      </button>
                                      <button
                                        onClick={() =>
                                          navigate(
                                            `/checkout/${ganhadorInfo?.orderId}`,
                                          )
                                        }
                                        className="my-1 p-2 bg-green-500 hover:bg-green-600 text-white rounded-xl"
                                      >
                                        Ir para o pedido (Contém as informações
                                        pessoais do ganhador!)
                                      </button>
                                      {exibirTelefone ? (
                                        <div className="my-1">
                                          Nome do ganhador:{' '}
                                          <strong>{ganhador}</strong>
                                          <br />
                                          Telefone do ganhador:{' '}
                                          <strong>
                                            {ganhadorInfo?.telefone}
                                          </strong>
                                        </div>
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {sidePremios.length > 0 ? (
                  <>
                    <div className="flex flex-col w-full p-2">
                      <span className="p-2 mb-2 rounded-xl bg-amber-200 border border-amber-500 text-amber-800 text-justify">
                        Para definir os ganhadores secundários, adicione as
                        cotas à lista abaixo e clique em "salvar". Os ganhadores
                        aparecerão em order no espaço abaixo!
                      </span>
                      <h1 className="text-2xl font-medium">
                        Prêmios secundários
                      </h1>
                      <div className="w-full flex flex-row items-center">
                        <Box
                          component="form"
                          sx={{
                            '& > :not(style)': { my: 1, width: '98%' },
                            width: '100%',
                          }}
                          noValidate
                          autoComplete="off"
                        >
                          <TextField
                            id="outlined-basic"
                            label="Novo número do título ganhador *"
                            variant="outlined"
                            color="success"
                            value={sideWinnerNumber}
                            onChange={(e) =>
                              setSideWinnerNumber(
                                masks.removeNonNumericChars(e.target.value),
                              )
                            }
                          />
                        </Box>
                        <button
                          className="w-fit h-fit p-2 bg-green-500 text-white rounded-xl m-2 hover:bg-green-600 transition ease-in-out"
                          onClick={(e) => handleSubmitSideWinner(e)}
                        >
                          Enviar
                        </button>
                      </div>
                      {enableSideWinnerButton ? (
                        <>
                          <button
                            className="w-full h-fit p-2 bg-green-500 text-white rounded-xl my-1 hover:bg-green-600 transition ease-in-out"
                            onClick={(e) => handleSetSideWinners()}
                          >
                            Salvar
                          </button>
                        </>
                      ) : (
                        <>
                          <button className="w-full h-fit p-2 bg-green-600 text-white rounded-xl my-1">
                            Procurando ganhadores...
                          </button>
                        </>
                      )}

                      <ul className="list-disc list-inside">
                        {sideWinners?.map((premio: any, index: number) => (
                          <div
                            key={index}
                            className="my-1 border rounded-lg p-2 shadow-md"
                          >
                            {index + 2}° Número ganhador: {premio}
                          </div>
                        ))}
                      </ul>
                      <div className="flex flex-col w-full mt-4">
                        <h1 className="flex flex-col lg:flex-row text-2xl font-medium items-center">
                          Ganhadores secundários
                          <span
                            onClick={() =>
                              setExibirInfoPessoalSideWinners(
                                !exibirInfoPessoalSideWinners,
                              )
                            }
                            className="ml-0 lg:ml-2 text-xs text-gray-800 font-normal px-2 py-1 border shadow-sm rounded-lg cursor-pointer hover:bg-gray-200 hover:text-black transition ease-in-out"
                          >
                            {' '}
                            {exibirInfoPessoalSideWinners
                              ? 'Ocultar informações pessoais'
                              : 'Ver informações pessoais'}
                          </span>
                        </h1>
                        <ul className="list-disc list-inside">
                          {sidePremios?.map((premio: any, index: number) => (
                            <div
                              key={index}
                              className="my-1 border rounded-lg p-2 shadow-md flex flex-col w-full"
                            >
                              <h1 className="text-lg montserrat-500">
                                {index + 2}° Prêmio: {premio.titulo}
                              </h1>
                              <h1>Número ganhador: {premio?.cota}</h1>
                              <h1>Ganhador: {premio?.comprador}</h1>
                              {exibirInfoPessoalSideWinners ? (
                                <>
                                  <h1>
                                    Telefone:{' '}
                                    {masks.brPhoneNumberMask(premio?.telefone)}
                                  </h1>
                                </>
                              ) : (
                                <>
                                  <h1>Telefone: (**) *****-*****</h1>
                                </>
                              )}
                            </div>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </>
                ) : (
                  <> </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
