import axios from 'axios';
const getBaseDomain = () => {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    // Se houver mais de duas partes, remova a primeira (subdomínio)
    if (parts.length > 2) {
        parts.shift();
    }
    return parts.join('.');
};

// Função para construir a URL base dinamicamente
const getBaseURL = () => {
    const baseDomain = getBaseDomain();
    return `https://api.${baseDomain}/`;
};

// Crie uma instância do axios com a URL base dinâmica
const apiClient = axios.create({
    baseURL: getBaseURL(),
    withCredentials: true,
    headers: {
        'Content-type': 'application/json'
    }
});

export default apiClient;