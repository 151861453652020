/* eslint-disable prefer-const*/

export class Masks {
  constructor() {}

  removeNonNumericCharsWithDot(value: any): string {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value.replace(/[^0-9.,]/g, '');
  }

  removeNonNumericCharsWithDotNumeric(value: any): number {
    if (typeof value !== 'string') {
      value = String(value);
    }
    let newValue = value.replace(/[^0-9.,]/g, '');
    newValue = parseFloat(newValue);
    newValue = newValue.toFixed(2);
    return parseFloat(newValue);
  }

  removeNonNumericChars(value: any): string {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value.replace(/[^0-9]/g, '');
  }

  brPhoneNumberMask(value: any): string {
    if (typeof value !== 'string') {
      value = String(value);
    }
    let onlyNumbers = this.removeNonNumericChars(value);
    if (onlyNumbers.length > 11) {
      onlyNumbers = onlyNumbers.slice(0, 11);
    }
    return onlyNumbers.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }

  brlMoneyMask(value: any): string {
    if (typeof value !== 'string') {
      value = String(value);
    }
    const onlyNumbers = this.removeNonNumericCharsWithDot(value);
    const money = Number(onlyNumbers) / 100;
    return money.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  emailMask(value: string): string {
    let email = value;
    return email.toLowerCase().trim();
  }

  setZeroLeft(value: string, length: number): string {
    value = this.removeNonNumericChars(value);
    return value.padStart(length, '0');
  }

  formatDateToBrazilian(dateString: string): string {
    if (!dateString) {
      return '';
    }

    const [datePart, timePart] = dateString.split(', ');
    if (!datePart || !timePart) {
      throw new Error('Invalid date format');
    }

    const [month, day, year] = datePart.split('/').map(Number);
    if (!month || !day || !year) {
      throw new Error('Invalid date parts');
    }

    const [time, modifier] = timePart.split(' ');
    if (!time || !modifier) {
      throw new Error('Invalid time format');
    }

    let [hours, minutes, seconds] = time.split(':').map(Number);
    if (hours === undefined || minutes === undefined || seconds === undefined) {
      throw new Error('Invalid time parts');
    }

    if (modifier === 'PM' && hours < 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    return `${formattedDate}, ${formattedTime}`;
  }

  formatISODateToBrazilian(dateString: string): string {
    if (!dateString) {
      return '';
    }

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    return `${formattedDate}, ${formattedTime}`;
  }

  formatBrazilianCPF(value: string): string {
    if (typeof value !== 'string') {
      value = String(value);
    }
    value = this.removeNonNumericChars(value);
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  unfornatBrazilianCPF(value: string): string {
    if (typeof value !== 'string') {
      value = String(value);
    }
    if (value.length > 11) {
      value = value.slice(0, 11);
    }
    return value.replace(/[^\d]/g, '');
  }
}
