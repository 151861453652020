import HeaderMain from '../generalComponents/HeaderMain';
import { useEffect, useState } from 'react';
import api from '../generalComponents/api';
import Swal from 'sweetalert2';

export default function TermosDeUso() {
  const [termos, setTermos] = useState('');

  useEffect(() => {
    if (typeof document == "undefined") return
    if (typeof window == "undefined") return

    const getTermos = async () => {
      if (typeof document == "undefined") return
      if (typeof window == "undefined") return

      const domain = window.location.hostname

      const response = await api
        .get("/cookie")
        .catch((error) => console.log(error))
      const _csrf = response?.data?.csrfToken
      api.defaults.headers.common["X-CSRF-Token"] = _csrf

      await api
        .get("/client/getAccountConfig", { params: { dominio: domain } })
        .then((response) => {
          setTermos(response.data.termos)
        })
        .catch((error) => {
          if (error.response.data.code) {
            Swal.fire({
              position: "top",
              icon: "error",
              title: `ERRO - ${error.response.data.code}`,
              text: `${error.response.data.error}`
            })
          }
        })
    }

    getTermos()
  }, [])

  return (
    <>
      <div className="flex flex-col w-full min-h-screen bg-gray-100">
        <HeaderMain />
        <div className="w-full p-4 h-full flex flex-col">
          <h1 className="my-2 text-3xl font-medium">Termos de uso</h1>
          <div className="bg-white flex-grow rounded-xl shadow-md p-3 text-justify">
            <p>{termos}</p>
          </div>
          <h1 className="my-4 w-full text-center">
            Precisa de ajuda? Entre em contato clicando{' '}
            <a href="" className="text-green-600 font-medium underline">
              aqui!
            </a>
          </h1>
        </div>
      </div>
    </>
  );
}
